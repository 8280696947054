import axios from "axios";

const serverUrl = process.env.REACT_APP_SERVER_URL;

export const forgotPassword = async (email) => {
  try {
    await axios.post(
      `${serverUrl}/auth/forgot-password`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return true;
  } catch (error) {
    console.error("Error:", error);
    throw new Error(
      "Failed to send password reset instructions. Please try again."
    );
  }
};

export const resetPassword = async (resetToken, password, confirmPassword) => {
  if (password !== confirmPassword) {
    throw new Error("Passwords do not match");
  }

  try {
    const response = await axios.post(
      `${serverUrl}/auth/reset-password`,
      {
        resetToken,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return true;
    }
  } catch (err) {
    console.error("Error:", err);
    throw new Error("Failed to reset password. Please try again.");
  }
};
