import React from "react";
import headerImage from "../../../assets/images/dashboard-sensy.svg";
import ellipse_right from "../../../assets/images/ellipse-right.svg";
import "./header.css";
import Navbar from "../navbar/Navbar";
import styles from "../../../style";
import { useUser } from "../../../state/UserContext";

const Header = () => {
  return (
    <div className="text-white w-full relative">
      <div className="absolute ">
        <Navbar />
      </div>

      <section
        className={`${styles.paddingX} relative flex flex-col justify-center items-center mt-[150px]`}
      >
        <div>
          <img
            src={ellipse_right}
            alt="Header Image"
            className="right-0 absolute -z-10 bottom-0"
          />
        </div>
        <span className="welcome max-w-[280px] rounded-[8px] text-[16px] sm:text-[18px] p-[16px] text-[#168004] flex justify-center items-center gap-3 font-Poppins font-[700]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="32"
            viewBox="0 0 31 32"
            fill="none"
          >
            <path
              d="M30.3009 19.0219C30.3009 26.1893 23.6751 31.9996 15.5017 31.9996C7.32825 31.9996 0.702393 26.1893 0.702393 19.0219C0.702393 11.8544 7.32825 6.04407 15.5017 6.04407C23.6751 6.04407 30.3009 11.8544 30.3009 19.0219Z"
              fill="white"
            />
            <path
              d="M4.44483 15.998C4.44483 17.1762 3.48688 18.1313 2.30518 18.1313C1.12348 18.1313 0.165527 17.1762 0.165527 15.998C0.165527 14.8198 1.12348 13.8646 2.30518 13.8646C3.48688 13.8646 4.44483 14.8198 4.44483 15.998Z"
              fill="#168004"
            />
            <path
              d="M30.8342 15.998C30.8342 17.1762 29.8763 18.1313 28.6946 18.1313C27.5129 18.1313 26.5549 17.1762 26.5549 15.998C26.5549 14.8198 27.5129 13.8646 28.6946 13.8646C29.8763 13.8646 30.8342 14.8198 30.8342 15.998Z"
              fill="#168004"
            />
            <path
              d="M1.56882 9.77748C1.3892 7.77158 2.97437 6.04407 4.99464 6.04407H26.0031C28.0233 6.04407 29.6085 7.77157 29.4289 9.77748L28.2599 22.8315C28.1015 24.5998 26.6151 25.9552 24.8341 25.9552H6.16362C4.38259 25.9552 2.89617 24.5998 2.73781 22.8315L1.56882 9.77748Z"
              fill="#168004"
              fillOpacity="0.72"
            />
            <path
              d="M4.78222 12.0912C4.60083 10.0843 6.18638 8.35498 8.20778 8.35498H22.791C24.8124 8.35498 26.398 10.0843 26.2166 12.0912L25.4545 20.523C25.2948 22.2901 23.8089 23.6439 22.0289 23.6439H8.96987C7.18992 23.6439 5.70403 22.2901 5.54431 20.523L4.78222 12.0912Z"
              fill="#168004"
            />
            <path
              d="M11.0422 6.04536C11.0422 5.06352 11.8407 4.26758 12.8257 4.26758H18.174C19.159 4.26758 19.9575 5.06352 19.9575 6.04536H11.0422Z"
              fill="#168004"
            />
            <path
              d="M15.1431 1.42224H16.0346V4.26669H15.1431V1.42224Z"
              fill="white"
            />
            <path
              d="M16.5693 0.977778C16.5693 1.51779 16.1302 1.95556 15.5886 1.95556C15.047 1.95556 14.6079 1.51779 14.6079 0.977778C14.6079 0.437766 15.047 0 15.5886 0C16.1302 0 16.5693 0.437766 16.5693 0.977778Z"
              fill="#168004"
            />
            <path
              d="M21.9927 25.9543H9.00723L8.37549 30.3549C10.489 31.4027 12.9172 31.9988 15.5 31.9988C18.0827 31.9988 20.511 31.4027 22.6245 30.3549L21.9927 25.9543Z"
              fill="#168004"
              fillOpacity="0.72"
            />
            <path
              d="M8.81445 27.5543L8.99276 25.9543H22.009L22.1873 27.5543H8.81445Z"
              fill="#168004"
              fillOpacity="0.72"
            />
            <path
              d="M13.0042 13.6886C13.0042 14.8668 12.0462 15.822 10.8645 15.822C9.68281 15.822 8.72485 14.8668 8.72485 13.6886C8.72485 12.5104 9.68281 11.5553 10.8645 11.5553C12.0462 11.5553 13.0042 12.5104 13.0042 13.6886Z"
              fill="white"
            />
            <path
              d="M12.467 13.6885C12.467 14.2776 11.9881 14.7552 11.3972 14.7552C10.8064 14.7552 10.3274 14.2776 10.3274 13.6885C10.3274 13.0994 10.8064 12.6218 11.3972 12.6218C11.9881 12.6218 12.467 13.0994 12.467 13.6885Z"
              fill="#168004"
            />
            <path
              d="M15.5007 20.4442C16.6824 20.4442 17.6404 19.4891 17.6404 18.3109H13.3611C13.3611 19.4891 14.319 20.4442 15.5007 20.4442Z"
              fill="white"
            />
            <path
              d="M22.2734 13.6886C22.2734 14.8668 21.3155 15.822 20.1338 15.822C18.9521 15.822 17.9941 14.8668 17.9941 13.6886C17.9941 12.5104 18.9521 11.5553 20.1338 11.5553C21.3155 11.5553 22.2734 12.5104 22.2734 13.6886Z"
              fill="white"
            />
            <path
              d="M20.6707 13.6885C20.6707 14.2776 20.1917 14.7552 19.6008 14.7552C19.01 14.7552 18.531 14.2776 18.531 13.6885C18.531 13.0994 19.01 12.6218 19.6008 12.6218C20.1917 12.6218 20.6707 13.0994 20.6707 13.6885Z"
              fill="#168004"
            />
          </svg>
          <span>Welcome To Sensy32</span>
        </span>
        <h1 className="text-[#343434] text-[36px] sm:text-[58px] font-[700] font-Poppins text-center my-[32px]">
          The Future of Sensy Interaction
        </h1>
        <div>
          <img
            src={headerImage}
            alt="Header Image"
            className="object-cover hero-img mt-5"
          />
        </div>
      </section>
    </div>
  );
};

export default Header;
