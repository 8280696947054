import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    snackbarStyleViaContentProps: () => ({
        backgroundColor: '#E0E0DB',

    }),
    snackbarStyleViaNestedContent: {
        backgroundColor: "#E0E0DB",
        color: "green"
    }
};

function CustomSnackbar({ classes, openSnackbar, closeSnackbar, message }) {

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            open={openSnackbar}
            autoHideDuration={3000}
            ContentProps={{
                "aria-describedby": "message-id",
                className: classes.snackbarStyleViaContentProps
            }}
            message={
                <span id="message-id">
                    <div style={{ color: '#168004', fontWeight: 'bold' }}>{message}</div>
                </span>
            }
            onClose={closeSnackbar}
        />
    );
}

export default withStyles(styles)(CustomSnackbar);
