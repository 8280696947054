import axios from "axios";


const fetchBoards = async (userId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ` + token

    };
    // Get user's boards list
    const url = `${process.env.REACT_APP_SERVER_URL}/boards/by-user/${userId}`;
    const response = await axios.get(url, { headers });
    return response.data.boards;
  } catch (error) {
    console.error("Error fetching boards:", error);
    throw error;
  }
};

const fetchNbOfBoards = async (userId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ` + token
    };
    // Get user's boards list
    const url = `${process.env.REACT_APP_SERVER_URL}/boards/by-user/${userId}`;
    const response = await axios.get(url, { headers });
    return response.data.boards.length;
  } catch (error) {
    console.error("Error fetching number of boards:", error);
    throw error;
  }
}

const getBoardNameById = (boardId, users) => {
  const board = users.find((item) => item.id === boardId);
  return board ? board.name : null;
}

const getBoardById = async (boardId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ` + token

    };
    const url = `${process.env.REACT_APP_SERVER_URL}/boards/${boardId}`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.error(`Error fetching board by id ${boardId}:`, error);
    throw error;
  }
};
export { fetchBoards, getBoardNameById, getBoardById, fetchNbOfBoards };
