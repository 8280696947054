import React from "react";
import "./features.css";
import styles, { typo } from "../../../style";
import highlights_1 from "../../../assets/icons/highlights_1.svg";
import highlights_2 from "../../../assets/icons/highlights_2.svg";
import highlights_3 from "../../../assets/icons/highlights_3.svg";
import highlights_4 from "../../../assets/icons/highlights_4.svg";
import highlights_5 from "../../../assets/icons/highlights_5.svg";

export default function Features() {
  const features = [
    {
      header: "Comprehensive Sensor Suite",
      paragraph:
        "From UV light detection to a 9-DOF Orientation IMU Sensor, Sensy32 is equipped with a wide range of sensors. Whether it's monitoring environmental conditions with the Humidity and Temperature Sensor or exploring motion dynamics with the Accelerometer/ Magnetometer/ Gyro Sensor, Sensy32 has it all.",
      img: highlights_1
    },
    {
      header: "Advanced Connectivity",
      paragraph:
        "Powered by ESP32-S3, Sensy32 offers seamless Wifi and Bluetooth capabilities, ensuring your projects stay connected",
      img: highlights_2
    },
    {
      header: "Intuitive Dashboard",
      paragraph:
        "Our advanced cloud dashboard is free to use and perfectly complements Sensy32. It's designed for easy monitoring",
      img: highlights_3
    },
    {
      header: "Ready-to-Use Code",
      paragraph:
        "Jumpstart your projects with fully functional sample code for all sensors. Whether you're a seasoned developer or a curious beginner",
      img: highlights_4
    },
    {
      header: "Built-in LCD Screen",
      paragraph:
        "Sensy32's integrated LCD screen is a standout feature, offering immediate visual feedback and interaction",
      img: highlights_5
    },
  ];

  return (
    <section className={`${styles.paddingX} mb-[130px]`}>
      <h2 className={`${typo.h3} mt-[50px]`}>Feature Highlights</h2>

      <div className="flex flex-wrap justify-center items-center sm:items-stretch gap-[60px] mt-[50px] sm:mt-[100px]">
        {/* Card */}
        {features.map((card, index) => {
          return (
            <div
              key={index}
              className="feature-card flex flex-col flex-1 sm:min-w-[364px] w-full bg-[#FBFBFB] rounded-[15px] p-[30px] text-left"
            >
              <div className="icon-bg rounded-[8px] p-[15px] max-w-[70px] flex justify-center">
                <img
                  src={card.img}
                  alt={card.header}
                // style={{color: "#168004"}}
                />
              </div>
              <h4 className="text-[#42526B] text-[18px] font-Inter font-[500] mb-[10px] mt-[20px]">
                {card.header}
              </h4>
              <p className="text-[#42526B] text-[15px] font-Inter font-[400] leading-[22px]">
                {card.paragraph} <br />
                <span className="text-[#168004] underline cursor-pointer  font-[500]">
                  See More
                </span>
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
}
