const styles = {
  boxWidth: "xl:max-w-[1280px] w-full",

  heading2:
    "font-rubik font-[500] ss:text-[38px] text-[28px] text-primary ss:leading-[56.8px] sm:text-left text-center leading-[50.8px] w-full",
  paragraph:
    "font-lato font-[400] text-links text-[18px] leading-[30.8px] text-dark_gray",

  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",

  paddingX: "sm:px-[100px] px-8",
  paddingY: "sm:py-16 py-6",
  padding: "sm:px-16 px-6 sm:py-12 py-4",

  paddingDX: "sm:px-9 px-6",

  marginX: "sm:mx-16 mx-6",
  marginY: "sm:my-16 my-6",
};

export const layout = {
  section: `flex md:flex-row flex-col ${styles.paddingY}`,
  sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY}`,

  sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-44 mr-0 md:mt-0 mt-10 ml-[-50px] relative`,
  sectionImg: `flex-1 flex ${styles.flexCenter} md:ml-10 ml-0 md:mt-0 mt-10 relative`,

  sectionInfo: `flex-1 ${styles.flexStart} flex-col `,
};

export const colors = {
  primary: localStorage.getItem("theme-color"),
  pages_header: "#343434",
  links: "#343434",
};

export const typo = {
  title: "text-left text-[#343434] sm:text-[60px] text-[40px] font-[700]",
  h2: "text-[#343434] text-[36px] sm:text-[50px] font-[700] font-Poppins text-center",
  h3: "text-[#061C3D] text-[32px] sm:text-[42px] font-[600] font-Inter text-center",
  section_title: "ml-2 text-[22px] font-[600] text-[#3E3232]",
  pages_header: "text-[#202020] text-[24px] font-[600]",
  card_header: "text-[15px] text-[#3E3232] font-[500]",
  card_description: "text-[14px] font-[400] my-[15px]",
  sidebar_links:
    "relative flex items-center py-3 px-3 my-[16px] font-medium cursor-pointer text-[#818181] hover:bg-[#F8FCFF] hover:rounded-[40px] group",
  footer_headers:
    "font-poppins font-[700] sm:text-[22px] text-[19px] leading-[27px] text-primary",
  footer_links:
    "font-poppins flex gap-2 font-normal sm:text-[17px] text-[15px] leading-[24px] text-[#171717] hover:text-secondary cursor-pointer",
};

export default styles;
