import React, { useContext, useEffect, useState } from "react";
import notif from "../assets/images/notif-icon.svg";
import moon from "../assets/images/moon.svg";
import menu from "../assets/images/menu.svg";
import close from "../assets/images/close.svg";
import { MenuContext } from "../pages/Layout";
// import { SidebarItem } from "./Sidebar";
import { useLocation, useNavigate } from "react-router";
import logout_icon from "../assets/icons/logout_icon.svg";
import { ThemeContext, useTheme } from "../state/ThemeContext";
import { useUser } from "../state/UserContext";
import Notifications from "./notifications/Notifications";
import { io } from "socket.io-client";
import { fetchNbOfBoards } from "../services/boardService";

const SecondHeader = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useContext(MenuContext);
  const navigate = useNavigate();
  const { userData, setUser } = useUser();
  const element = document.documentElement;
  const location = useLocation();
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [nbNewNotifs, setNbNewNotif] = useState(0);

  useEffect(() => {
    const url = `${process.env.REACT_APP_SERVER_URL}`;
    const socket = io(url);
    socket.on("notification", (notification) => {
      // Update the state to increment the number of new notifications
      setNbNewNotif(prevCount => prevCount + 1);
    });
    return () => {
      socket.disconnect();
    };
  }, []);


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const myParam = searchParams.get("data");
    if (myParam) {
      const { user, token } = JSON.parse(myParam);
      setUser(user);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
    }
  }, [location.search]);
  const { theme, toggleTheme } = useContext(ThemeContext);
  localStorage.setItem("themeMode", theme);
  const handleToggleTheme = () => {
    toggleTheme();
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
    setNbNewNotif(0);
  };

  const iconStyle = {
    filter: theme === "dark" ? "invert(1)" : "invert(0)",
  };

  return (
    <div
      className={`p-4 sm:pt-[30px] pt-[22px] relative flex justify-between items-center ${theme === "dark" ? "bg-[#202020]" : "bg-white"
        }`}
    >
      <div className="flex gap-5 pb-[1.5rem]">
        <div
          className={`flex sm:hidden pl-2 ${mobileMenuOpen && "pl-4"
            } pt-1 z-10`}
        >
          <button
            type="button"
            className="absolute right-0 mx-5 cursor-pointer inline-flex items-center justify-center rounded-md  text-gray-700"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <span className="sr-only">Open main menu</span>
            {mobileMenuOpen ? (
              <img
                src={close}
                alt="close icon"
                style={iconStyle}
                height="28px"
                width="28px"
              />
            ) : (
              <img
                src={menu}
                alt="menu icon"
                style={iconStyle}
                height="28px"
                width="28px"
              />
            )}
          </button>
        </div>

      </div>
      <div className="flex space-x-8 sm:pr-[62px] pr-[22px] header-right-container">

        <button className="text-white relative" onClick={toggleNotifications}>
          <img src={notif} alt="notification icon" />
          {nbNewNotifs > 0 && (
            <div className="absolute top-0 left-3 right-0 h-4 w-4 bg-red-500 rounded-full flex items-center justify-center">
              <span className="text-xs text-white font-bold">{nbNewNotifs}</span>
            </div>
          )}
        </button>

        {/* <button className="text-white" onClick={toggleNotifications}>
          <img src={notif} alt="notification icon" />
        </button> */}
        <button className="text-white " onClick={toggleTheme}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="30"
            viewBox="0 0 39 36"
            fill="none"
          >
            <path
              d="M14.4643 28.2083C14.4643 29.4332 14.6732 30.6291 15.0589 31.7374C8.88749 29.7978 4.22678 24.6499 3.74464 18.627C3.26249 12.2249 7.32856 6.24575 13.9018 3.73742C15.6053 3.09575 16.4732 3.56242 16.8428 3.89783C17.1964 4.21867 17.6946 4.99158 16.9875 6.4645C16.2643 7.98117 15.9107 9.58533 15.9107 11.2478C15.9268 14.2228 17.2125 16.9791 19.3018 19.0937C16.3607 21.2228 14.4643 24.5187 14.4643 28.2083Z"
              fill="#666666"
            />
            <path
              d="M34.0875 26.3417C30.9054 30.2646 25.8589 32.5687 20.475 32.5687C20.2179 32.5687 19.9607 32.5542 19.7036 32.5396C18.0964 32.4812 16.5375 32.2042 15.0589 31.7375C14.6732 30.6292 14.4643 29.4333 14.4643 28.2083C14.4643 24.5187 16.3607 21.2229 19.3018 19.0938C21.6643 21.5 25.0554 23.0604 28.8 23.2062C29.8125 23.25 30.825 23.1771 31.8214 23.0167C33.6214 22.725 34.3447 23.3375 34.6018 23.7313C34.875 24.125 35.1643 24.9854 34.0875 26.3417Z"
              fill={
                localStorage.getItem("theme-color") ||
                userData.themeColor ||
                "#168004"
              }
            />
          </svg>
          {/* <img src={moon} alt="moon icon" width="35px" height="35px" /> */}
        </button>
        <Notifications showNotifications={showNotifications} />

      </div>
    </div>
  );
};

export default SecondHeader;
