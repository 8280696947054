import React, { useEffect } from "react";
import Header from "../../components/home/header/Header";
import Features from "../../components/home/features/Features";
import Footer from "../../components/home/footer/Footer";
import Description from "../../components/home/description/Description";
import FeatureUses from "../../components/home/featuresUses/FeatureUses";
import Section5 from "../../components/home/section5/Section5";
import Section6 from "../../components/home/section6/Section6";
import GetStarted from "../../components/home/getStarted/GetStarted";
import ContactUs from "../../components/home/contactUs/ContactUs";
import { useLocation } from "react-router";
import { useUser } from "../../state/UserContext";
import ellipse from "../../assets/images/ellipse-header.svg";
import { fetchNbOfBoards } from "../../services/boardService";

export default function LandingPage() {
  const { userData, setUser } = useUser();
  const location = useLocation();

  const fetchUserNbBoards = async (userId) => {
    const nbBoards = await fetchNbOfBoards(userId);
    return nbBoards;
  }
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const myParam = searchParams.get("data");
    if (myParam) {
      const { user, token } = JSON.parse(myParam);
      setUser(user);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      fetchUserNbBoards(user.id).then((nbOfBoards) => {
        localStorage.setItem("nb_boards", nbOfBoards);
        setTimeout(() => {
          localStorage.setItem("theme-color", "#168004");
          window.location.href = "/home-dashboard";
        }, 1000);
      });
    }
  }, []);
  return (
    <div>
      <div>
        <img
          src={ellipse}
          alt="Header Image"
          className="top-0 left-0 absolute z-[-10000000]"
        />
      </div>
      <Header />
      <Description />
      <div id="features">
        <Features />
      </div>
      <FeatureUses />
      <Section5 />
      <div id="why-choose-us">
        <Section6 />
      </div>
      <GetStarted />
      <ContactUs />
      <Footer />
    </div>
  );
}
