const getTimeDifference = (timestamp) => {
    const logDate = new Date(timestamp);
    const currentDate = new Date();

    const timeDifference = currentDate - logDate;
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));

    if (minutesDifference < 60) {
        return `${minutesDifference} min ago`;
    } else if (minutesDifference < 24 * 60) {
        const hoursDifference = Math.floor(minutesDifference / 60);
        return `${hoursDifference} h ago`;
    } else {
        const daysDifference = Math.floor(minutesDifference / (60 * 24));
        if (daysDifference === 1) {
            return 'yesterday';
        } else {
            return `${daysDifference} days ago`;
        }
    }
};

export { getTimeDifference };