import React from 'react';
import './coming-soon.css';
import logo from '../../assets/images/logo.svg';
import { addMonths } from 'date-fns';
import CountDownTimer from './CountDownTimer';

export default function ComingSoon() {

  const currentDate = new Date();
  const targetDate = addMonths(currentDate, 1); 

  return (
    <div className="background-image flex flex-col grid place-content-evenly place-items-center">
        <img src={logo} alt="Logo" />

        <div className='page-title '>
              Coming Soon
        </div>

        <div className='text'>
            Dive into the future of sensor analytics with Sensy32.io.
        </div>

        <div className='stay-tuned'>
            Stay tuned!
        </div>

        <div >
          <CountDownTimer targetDate={targetDate} />
        </div>

        <div className='notification-text'>
            Get notified when we launch
        </div>

        <div className='notification'>
          <form action="" className="flex flex-col gap-3 sm:flex-row sm:gap-0 w-full mx-auto">
            <input type="email" name="email" id="email" className="w-full sm:w-4/6 rounded border-2 border-gray-300 py-3 px-3 focus:border-emerald-500 focus:outline-none sm:rounded-l-md sm:rounded-r-none sm:border-r-0" placeholder="Email" />
            <button type="submit" className="w-full sm:w-2/6 rounded button-comming-soon px-5 py-4 font-bold text-white sm:rounded-l-none sm:rounded-r-md">Subscribe</button>
          </form>
        </div>
    </div>
  );
}
