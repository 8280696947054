import React from 'react';
import Navbar from '../../components/home/navbar/Navbar';
import './documentation.css';
import img1 from '../../assets/images/code-execution1.png'
import img2 from '../../assets/images/image2.png'
import dashboard from '../../assets/images/dashboard.png'
import img3 from '../../assets/images/image3.png'
import code from '../../assets/images/image-code.png'
export default function Documentation() {
    return (
        <div>
            <Navbar />
            <div className="mx-auto px-4 mt-40">
                <div className="grid grid-cols-1 lg:grid-cols-4">
                    <div className="lg:col-span-1 max-w-[300px] bg-[#FDFFFD] hidden lg:flex flex-col gap-10 ml-6 items-start documentation-section p-4 h-screen ">
                        <div className='documentation-menu-items'>
                            <a href="#introduction" className="menu-item">Introduction</a>
                            <a href="#arduino-ide" className="menu-item">Setting Up the Arduino IDE</a>
                            <a href="#executing-code" className="menu-item" >Writing and Executing Code</a>
                            <a href="#trouble" className="menu-item">Troubleshooting Guide</a></div>
                    </div>

                    <div className="lg:col-span-3 flex flex-col items-start gap-4">
                        <div className='text-[#1E1147] text-[20px] font-[500]'>
                            Please visit our website to start reading data from sensors :
                        </div>
                        <div className='text-[#168004] flex justify-items items-center text-center  text-[14px] font-[700] commande'> <a href='https://sensy32.io/'>https://sensy32.io/</a></div>

                        <div className='text-[#1E1147] text-[20px] font-[700]'> Software you will need : </div>
                        <div className='text-[#1E1147] text-[18px] font-[500] '>Arduino : <span className='text-[#168004] text-[14px] font-[700] '><a href='https://www.arduino.cc/en/software'> https://www.arduino.cc/en/software</a></span> </div>
                        <div className='text-[#1E1147] text-[18px] font-[500] '>CP210xVCP Driver: <span className='text-[#168004] text-[14px] font-[700] '><a href='https://www.silabs.com/developers/usb-to-uart-bridge-vcp-drivers'>https://www.silabs.com/developers/usb-to-uart-bridge-vcp-drivers</a></span> </div>


                        <div className='text-[#1E1147] text-[18px] font-[700] '>Hardware you will need : </div>
                        <span className='text-[#168004] text-[18px] font-[700] '>- Sensy32 Board</span>
                        <div id="introduction" className='text-[#1E1147] text-[20px] font-[700]'>
                            Documentation:
                        </div>
                        <div id='arduino-ide' className='documentation-section flex flex-col gap-4 items-start p-8 w-full justify-items'>
                            <div className='text-[#168004] text-[18px] font-[700] flex  justify-items items-start'>Setting Up the Arduino IDE : </div>
                            {/* <ul className='text-start justify-items mr-4 mt-4'> */}
                            <div className='text-[#1E1147] text-[10px] lg:text-[14px]  flex text-start justify-items font-[600]'> 1. Download and install the Arduino IDE from the Arduino website:<a href='https://www.arduino.cc/en/software'>https://www.arduino.cc/en/software</a> </div>
                            <div className='text-[#1E1147] text-[10px]  lg:text-[14px]  flex text-start justify-items font-[600] '>2. Open the Arduino IDE and select "Settings" from the "File" menu.</div>
                            <div className='text-[#1E1147] text-[10px]  lg:text-[14px]  flex text-start justify-items font-[600]'>3. Under "Additional Boards Manager URLs", add the following URL: <a href=" https://dl.espressif.com/dl/package_esp32_index.json"> https://dl.espressif.com/dl/package_esp32_index.json</a></div>
                            <div className='text-[#1E1147] text-[10px] lg:text-[14px]  flex text-start justify-items font-[600]'>4.Click "OK" and then click "Tools" - "Board" - "Boards Manager".</div>
                            <div className='text-[#1E1147] text-[10px]  lg:text-[14px]  flex text-start justify-items font-[600]'>5. Search for " ESP32S3 Dev Module " and install the ESP32 board package.</div>
                            {/* </ul> */}
                        </div>

                        <div className='documentation-section flex flex-col items-start p-8 w-full justify-items'>
                            <div className='text-[#168004] text-[18px] font-[700] flex items-start'>Connecting the Board :  </div>
                            <div className='flex flex-col gap-4 mt-2 items-start justify-items ml-2'>
                                <div className=' text-[#1E1147] text-[10px] lg:text-[14px]  flex text-start justify-items font-[600]'>1.Connect the SENSY32 board to your computer using the USB-C cable.</div>
                                <div className=' text-[#1E1147] text-[10px] lg:text-[14px]  flex text-start justify-items font-[600]' >2.Select the correct board and port from the Arduino IDE. </div>
                                <p className='flex text-start text-[#1E1147] text-[16px] font-[500] ml-2'>
                                    To do this, go to Tools - Board and select ‘’ESP32S3 Dev Module’’.
                                    Then, go to Tools - Port and select the port that your SENSY32 development board is connected to.</p>

                            </div>
                            <img className='mx-auto my-auto mt-8' src={img2} />

                            <div className='text-[#168004] text-[16px] font-[700] ml-2'>
                                Note:
                            </div>
                            <div className='flex text-start text-[#1E1147] text-[16px] font-[500] ml-2'>
                                This command may help you detect whether the board is successfully connected. It shows a list of specific devices or files related to serial communication on your system.
                            </div>
                            <div className='text-[#1E1147] commande flex items-center justify-center text-center text-[20px] font-[700]'>ls /dev/cu.*</div>

                        </div>
                        <div id='executing-code' className='documentation-section flex flex-col items-start p-8 w-full justify-items'>

                            <div className='text-[#168004] text-[18px] font-[700] flex items-start'>Writing and Executing Code : </div>
                            <div className='text-[#1E1147] text-[16px] flex  text-start font-[400] mt-4'>
                                Now, you can simply create a new sketch in the Arduino IDE and enter your code. Once you have finished writing your code, click the "Upload" button to upload the code to your SENSY32 board.
                            </div>
                            <div className='text-[#1E1147] text-[16px] flex  text-start font-[400] mt-2'>
                                To compile the code, simply click the "Verify/Compile" button.
                            </div>
                            <div className='title mt-4'>Troubleshooting</div>
                            <div className='text-[#1E1147] text-[16px] flex  text-start font-[400] mt-2'>If you are having trouble working with the SENSY32 development board & Arduino, here are a few tips:</div>

                            <ul className='text-start ml-3 mt-4'>
                                <li className='text-[#1E1147] text-[14px] flex  text-start font-[600]'> - Make sure that you have installed the correct board package in the Arduino IDE.</li>
                                <li className='text-[#1E1147] text-[14px] flex  text-start font-[600] '>- Make sure that you have selected the correct board and port in the Arduino IDE.</li>
                                <li className='text-[#1E1147] text-[14px] flex  text-start font-[600]'>- Some boards may need drivers to connect them with the USB to your laptop.</li>
                                <li className='text-[#1E1147] text-[14px] flex  text-start font-[600]'>- Make sure that the USB-C cable is properly connected to the board and your computer.</li>
                                <li className='text-[#1E1147] text-[14px] flex  text-start font-[600]'>- Try restarting the Arduino IDE and your computer.</li>
                                <li className='text-[#1E1147] text-[14px] flex  text-start font-[600]'>- If you are still having trouble, try searching for help online.</li>
                            </ul>

                            <div className='title mt-4'>Steps to execute the code : </div>
                            <div className='text-[#1E1147] text-[16px] flex  text-start font-[400] mt-2'>To execute the code successfully, you will need to:</div>
                            <ul className='text-start ml-3 mt-4'>
                                <li className='text-[#1E1147] text-[16px] flex  text-start font-[600]'>1. Make sure that you have the following libraries installed in the Arduino IDE:</li>
                                <li>
                                    <div className='flex space-x-2 ml-4'>
                                        <span className='rounded-[50px] border border-[#1E1147] w-2 h-2 mt-2'> </span>
                                        <span>SparkFunBME280.h</span>
                                    </div>
                                    <img className='mx-auto my-auto mt-8 ' src={img1} />
                                </li>

                                <li className='text-[#1E1147] text-[14px] flex  text-start font-[600] '>2. Connect the sensy32 development board to your computer using the USB-C cable.</li>
                                <li className='text-[#1E1147] text-[14px] flex  text-start font-[600]'>3. Update your Wi-Fi credentials here</li>
                                <li><img src={code} className='mx-auto my-auto mt-8 max-w-[600px]' /></li>
                                <li className='text-[#1E1147] text-[14px] flex  text-start font-[600]'>4. Click the "Upload" button to upload the code.</li>
                                <li className='text-[#1E1147] text-[14px] flex  text-start font-[600]'>5. Next, visit the website <a href='https://sensy32.io'>https://sensy32.io</a>  website and log in to your account. You will then be able to see the results sent by the board</li>
                            </ul>
                            <div className='text-[#168004] text-start text-[16px] font-[700]'>
                                Note:
                            </div>
                            <div className='text-[#1E1147] text-start text-[16px] font-[500]'>
                                This command may help you detect whether the board is successfully connected. It shows a list of specific devices or files related to serial communication on your system.
                            </div>
                            <div>
                                Here is an example of the real-time data that you should see on the website :

                            </div>
                            <img className='mx-auto my-auto mt-8 w-100' src={dashboard} />

                            <div className='text-[#1E1147] text-[16px] flex  text-start font-[400] mt-2'>To execute the code successfully, you will need to:</div>


                            <div className='text-[#1E1147] text-[16px] flex  text-start font-[600]'>
                                You will find the code in this file within the Sensy32 project:
                            </div>
                            <div className='text-[#168004] lg:text-[18px] text-[14px] font-[500]'>&rarr;  Examples &rarr; temperatureHumiditySensor.ino</div>
                            <div className='text-[#1E1147] text-[18px] font-[500]'>Sensor used in this API : <span className='text-[#168004]'>BME280</span> </div>
                            <div>The following libraries are used in the API:</div>

                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>SparkFunBME280.h: </span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>This library helps work with the SparkFun BME280 sensor, allowing you to read temperature, humidity, and pressure data. </div>
                            </div>

                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>Wire.h: </span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>This library makes it possible to communicate between microcontrollers and other devices using the I2C protocol.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>WiFi.h: </span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'> This library provides support for connecting to and managing WiFi networks.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>HTTPClient.h:</span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>This library provides support for making HTTP requests and responses.</div>
                            </div>
                            <div className='title mt-4'>Pressure & Altitude Sensor :</div>
                            <div className='text-[#1E1147] text-[16px] flex  text-start font-[600]'>
                                You will find the code in this file within the Sensy32 project:
                            </div>
                            <div className='text-[#168004] text-[18px] font-[500]'>&rarr;  Examples &rarr; pressureAltitudeSensor.ino</div>
                            <div className='text-[#1E1147] text-[18px] font-[500]'>Sensor used in this API : <span className='text-[#168004]'>BMP388</span></div>
                            <div>The following libraries are used in the API:</div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px]font-[500]'>BMP388_DEV.h: </span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>This library facilitates interaction with the BMP388 sensor module.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>WiFi.h:</span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>This library provides support for connecting to and managing WiFi networks.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>HTTPClient.h:</span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>This library provides support for making HTTP requests and responses.</div>
                            </div>

                            <div className='title mt-4'>UV Sensor :</div>
                            <div className='text-[#1E1147] text-[16px] flex  text-start font-[600]'>
                                You will find the code in this file within the Sensy32 project:
                            </div>
                            <div className='text-[#168004] text-[18px] font-[500]'>&rarr;  Examples &rarr;uvSensor.ino</div>
                            <div className='text-[#1E1147] text-[18px] font-[500]'>Sensor used in this API : <span className='text-[#168004]'>LTR-390UV-01</span></div>
                            <div>The following libraries are used in the API:</div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>Adafruit_LTR390.h: </span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>A library for communicating with the Adafruit_LTR390 UV sensor.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px]font-[500]'>WiFi.h:</span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px]font-[500]'>This library provides support for connecting to and managing WiFi networks.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>HTTPClient.h:</span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>This library provides support for making HTTP requests and responses.</div>
                            </div>
                            <div className='title mt-4'>Light Sensor:</div>
                            <div className='text-[#1E1147] text-[16px] flex  text-start font-[600]'>
                                You will find the code in this file within the Sensy32 project:
                            </div>
                            <div className='text-[#168004] text-[18px] font-[500]'>&rarr;  Examples &rarr; lightSensor.ino</div>
                            <div className='text-[#1E1147] text-[18px] font-[500]'>Sensor used in this API :  <span className='text-[#168004]'>TSL25911FN </span></div>
                            <div>The following libraries are used in the API:</div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>Wire.h: </span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>Used for I2C communication with the sensor.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>Adafruit_TSL2591.h:</span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>A library for communicating with the Adafruit_TSL2591 Light sensor.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>Adafruit_Sensor.h : </span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>provides common functionality for working without various sensors, simplifying sensor integration in projects.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>WiFi.h:</span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>This library provides support for connecting to and managing WiFi networks.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>HTTPClient.h:</span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>This library provides support for making HTTP requests and responses.</div>
                            </div>

                            <div className='title mt-4'>9DOF Orientation IMU Sensor :</div>
                            <div className='text-[#1E1147] text-[16px] flex  text-start font-[600]'>
                                You will find the code in this file within the Sensy32 project:
                            </div>
                            <div className='text-[#168004] text-[18px] font-[500]'>&rarr;  Examples &rarr;orientationSensor.ino</div>
                            <div>
                                For the accelerometer sensor ,you will find the code in this file :
                            </div>
                            <div className='text-[#168004] text-[18px] font-[500]'>&rarr;  Examples &rarr; accelerometerSensor.ino</div>
                            <div className='text-[#1E1147] text-[18px] font-[500]'>Sensor used in this API :<span className='text-[#168004]'>BNO085</span> </div>
                            <div>To execute the code successfully, you will need these Libraries:</div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>Wire.h: </span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>Used for I2C communication with the sensor.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px]font-[500]'>SparkFun_BNO08x_Arduino_Library</span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>simplifies interfacing with BNO08x series sensors in Arduino projects.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>WiFi.h:</span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>This library provides support for connecting to and managing WiFi networks.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>HTTPClient.h:</span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>This library provides support for making HTTP requests and responses.</div>
                            </div>

                            <div className='title mt-4'>PIR Motion Sensor :</div>
                            <div className='text-[#1E1147] text-[16px] flex  text-start font-[600]'>
                                You will find the code in this file within the Sensy32 project:
                            </div>
                            <div className='text-[#168004] text-[18px] font-[500]'>&rarr;  Examples &rarr; motionSensor.ino</div>
                            <div className='text-[#1E1147] text-[18px] font-[500]'>Sensor used in this API :<span className='text-[#168004]'>STHS34PF80</span> </div>
                            <div>The following libraries are used in the API:</div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>Wire.h: </span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>Used for I2C communication with the sensor.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>SparkFun_STHS34PF80_Arduino_Library.h:</span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>simplifies interfacing with the STHS34PF80 sensor in Arduino projects.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>WiFi.h:</span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>This library provides support for connecting to and managing WiFi networks.</div>
                            </div>
                            <div className='item flex flex-col w-full'>
                                <span className='text-[#636365] text-start lg:text-[18px] text-[14px] font-[500]'>HTTPClient.h:</span>
                                <div className='text-[#1E1147] text-start lg:text-[18px] text-[14px] font-[500]'>This library provides support for making HTTP requests and responses.</div>
                            </div>

                        </div>

                        <div id="trouble" className='documentation-section flex flex-col items-start p-8 w-full justify-items'>
                            <div className='text-[#168004] text-[18px] font-[700] flex text-start'>Troubleshooting Guide:</div>
                            <div className='text-[#168004] text-[18px] font-[700] flex text-start mt-2'>Hardware Issues:</div>
                            <div className='text-start'><strong>-Power Supply:</strong> Make sure your SENSY32 board is connected to a stable power source.</div>
                            <div className='text-start'><strong>-USB Cable:</strong> Use a good-quality USB cable to connect the board to your computer. Avoid damaged cables, and try a different one if needed.</div>
                            <div className='text-start'><strong>-Board Connection: </strong> Confirm that your board is correctly connected to the computer. Ensure the USB cable is securely plugged into both the board and the computer's USB port.</div>
                            <div className='text-start'><strong>-External Components:</strong>If your project involves external parts (sensors, actuators), check their connections and power supply, ensuring they are connected to the right pins.</div>

                            <div className='text-[#168004] text-[18px] font-[700] flex items-start mt-2'>Software Issues:</div>
                            <div className='text-start'><strong>-Compilation Errors:</strong> If the sketch compiles but doesn't upload, look for error messages in the Arduino IDE.</div>
                            <div className='text-start'><strong>-Library Issues:</strong> If using libraries, make sure you have the latest versions compatible with your SENSY32 board.</div>

                            <div className='text-[#168004] text-[18px] font-[700] flex items-start text-start mt-2'>Additional Tips:</div>
                            <div className='text-start'><strong>-Serial Monitor:</strong>  Use the Arduino IDE's Serial Monitor to track your program's output, helping identify errors.</div>
                            <div className='text-start'><strong>-Reset Button:</strong> Restart the program by pressing the reset button on the board to resolve temporary glitches.</div>
                            <div className='text-start'><strong>-Check Wi-Fi Credentials:</strong>  Ensure your code has the correct Wi-Fi credentials; otherwise, it won't work.</div>
                            <div className='text-start'><strong>-Wrong Boot Mode Issue:</strong> If facing the "Wrong boot mode detected" problem, follow the solution: Hold Boot button, click EN button, click Upload, release Boot button when "Connecting...." is displayed.</div>

                            <div className='text-start'><strong>-Do not disconnect the wiring: </strong> Avoid closing the wiring while the code is being uploaded to the board.</div>
                            <div className='text-start'><strong>-Serial Monitor Baud Rate: </strong> Always check that the baud rate in the serial monitor matches the one in your code.</div>
                            <img className='mx-auto my-auto mt-8 max-w-[600px]' src={img3} />
                            <div className='text-start'><strong>-Brownout Detector Issue:  </strong>  If encountering the "Brownout detector was triggered" problem, consider the possibilities:</div>
                            <div className='text-start'>* Poor-quality or too-long USB cable.</div>
                            <div className='text-start'>* Insufficient power from your computer's USB port.</div>
                            <div className='text-start'>* Other components affecting the power supply in your circuit might not be correctly wired up.</div>
                            <div className='text-start'><strong>-Check Port Settings: </strong>  Always verify that the correct port is selected in the Arduino IDE under "Tools" - "Port."</div>
                            <div className='text-start'><strong>-Review Code Comments:</strong> Check for comments in the code that may provide insights or explanations for specific sections. </div>

                        </div>
                    </div>


                </div>

            </div>
            {/* {showScrollButton && <ScrollButton onClick={handleScrollToTop} />} */}
        </div>

        // </div>
    );
}
