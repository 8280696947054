import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
const [userData, setUserData] = useState(JSON.parse(window.localStorage.getItem('user')));

const setUser = (user) => {setUserData(user)};
const getUser = () => {return userData};
return (
<UserContext.Provider value={{ userData, setUser, getUser }}>
{children}
</UserContext.Provider>
);
};

export const useUser = () => useContext(UserContext);