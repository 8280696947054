

import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch } from "react-redux";
import { setSelectedWidget } from "../../../state";
import { io } from 'socket.io-client';
import { ThemeContext } from "../../../state/ThemeContext";

const TempWidgetConsole = ({
  value,
  min,
  max,
  text,
  color,
  grow,
  chart,
  onEdit,
  onDelete,
  sensor,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useContext(ThemeContext).theme;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  // Define ITEM_HEIGHT here
  const ITEM_HEIGHT = 78;

  // Define the chart options
  const options = {
    chart: {
      type: "radialBar",
      height: 350,
    },
    foreColor: theme === "dark" ? "#ffffff" : "#000000",
    colors: [color],
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        hollow: {
          size: "65%",
        },
        track: {
          background: "#fff",
          color: color,
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#ccc",
            opacity: 1,
            blur: 2,
          },
        },

        dataLabels: {
          name: {
            show: true, // Show "Temperature" label
            offsetY: -10, // Offset label position
            color: color,
            fontWeight: "400",
            formatter: function () {
              return text;
            },
          },
          value: {
            fontSize: "35px",
            fontWeight: "bold",
            color: color,
            show: true,
            formatter: function (val) {
              val = parseInt(val);
              return sensor === "temperature"
                ? val.toFixed(1) + "°"
                : val.toFixed(1);
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        backgroundColor: color,
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Temperature"],
    background: {
      image: "",
      imageWidth: 0,
      imageHeight: 0,
      imageOffsetX: 0,
      imageOffsetY: 0,
    },
  };


  const handleEditClick = () => {
    // handleClose();
    dispatch(setSelectedWidget(chart));
    onEdit(chart);
  };

  const handleDeleteClick = () => {
    // handleClose();
    dispatch(setSelectedWidget(chart));
    onDelete(chart);
  };


  return (
    <div
      className={`spline-area-chart rounded-[12px] shadow-md  bg-white dark:bg-dark2 p-[15px]`}
    >
      <div className="flex justify-between items-center px-[20px]">
        <span className="dark:text-white">{text}</span>
        <div
          style={{
            backgroundColor: "rgba(210, 210, 210, 0.25)",
            borderRadius: "4px",
          }}
        >
          {/* <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton> */}
          {/* <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "120px",
              },
            }}
          >
            <MenuItem onClick={handleEditClick}>
              <span
                style={{
                  backgroundColor: "rgba(210, 210, 210, 0.25)",
                  borderRadius: "4px",
                  padding: "6px",
                  marginRight: "10px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1205_20263)">
                    <path
                      d="M6.36719 10.5491H11.0822"
                      stroke="black"
                      strokeWidth="1.17875"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.72483 1.90485C8.93325 1.69643 9.21592 1.57935 9.51067 1.57935C9.65661 1.57935 9.80112 1.60809 9.93596 1.66394C10.0708 1.71979 10.1933 1.80165 10.2965 1.90485C10.3997 2.00804 10.4816 2.13056 10.5374 2.26539C10.5933 2.40022 10.622 2.54474 10.622 2.69068C10.622 2.83662 10.5933 2.98114 10.5374 3.11597C10.4816 3.2508 10.3997 3.37332 10.2965 3.47651L3.7479 10.0251L1.65234 10.549L2.17623 8.45345L8.72483 1.90485Z"
                      stroke="black"
                      strokeWidth="1.17875"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1205_20263">
                      <rect
                        width="12.5733"
                        height="12.5733"
                        fill="white"
                        transform="translate(0.0805664 0.0712891)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <span className="text-[12px]">Edit</span>
            </MenuItem>
            <MenuItem onClick={handleDeleteClick}>
              <span
                style={{
                  backgroundColor: "rgba(210, 210, 210, 0.25)",
                  borderRadius: "4px",
                  padding: "4px",
                  marginRight: "10px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M4.6093 12.9918C4.6093 13.7012 5.18974 14.2817 5.89918 14.2817H11.0587C11.7681 14.2817 12.3486 13.7012 12.3486 12.9918V6.54238C12.3486 5.83294 11.7681 5.25249 11.0587 5.25249H5.89918C5.18974 5.25249 4.6093 5.83294 4.6093 6.54238V12.9918ZM12.3486 3.31767H10.7362L10.2783 2.85976C10.1622 2.74367 9.99456 2.67273 9.82687 2.67273H7.13102C6.96333 2.67273 6.79565 2.74367 6.67956 2.85976L6.22165 3.31767H4.6093C4.25458 3.31767 3.96436 3.60789 3.96436 3.96261C3.96436 4.31733 4.25458 4.60755 4.6093 4.60755H12.3486C12.7033 4.60755 12.9935 4.31733 12.9935 3.96261C12.9935 3.60789 12.7033 3.31767 12.3486 3.31767Z"
                    fill="black"
                  />
                </svg>
              </span>
              <span className="text-[12px]">Delete</span>
            </MenuItem>
          </Menu> */}
        </div>
      </div>
      {value && (
        <ReactApexChart
          options={options}
          series={[
            sensor == "temperature" ? value : 0
          ]}
          type="radialBar"
        />

      )}

      <div className="min-max-values flex justify-center gap-[20%]">
        <span className="min-value " style={{ color: color }}>
          {min}&deg;
        </span>
        <span className="max-value " style={{ color: color }}>
          {max}&deg;
        </span>
      </div>
    </div>
  );
};

export default TempWidgetConsole;

