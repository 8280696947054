import React from "react";
import dashboard_img from "../../../assets/images/why-choose-us.svg";
import styles, { typo } from "../../../style";

const Section6 = () => {
  const list = [
    {
      text: "Versatility:",
      paragraph:
        "Sensy32 is designed to cater to a wide range of applications – from DIY projects to complex industrial solutions.",
    },
    {
      text: "Ease of Use:",
      paragraph:
        "With a user-friendly interface and comprehensive documentation, getting started with Sensy32 is a breeze.",
    },
    {
      text: "Community and Support:",
      paragraph:
        "Join a growing community of enthusiasts and experts. Share ideas, get support, and collaborate on exciting projects",
    },
    {
      text: "Sustainability:",
      paragraph:
        "Sensy32’s energy-efficient design and battery charger feature make it a sustainable choice for eco-conscious projects.",
    },
  ];

  return (
    <section className={`${styles.paddingX} flex justify-center my-[120px]`}>
      <div className="flex gap-[100px] flex-wrap justify-center lg:justify-between w-full">
        <img
          src={dashboard_img}
          alt="dashboard"
          className="w-full lg:max-w-[600px]"
        />
        <div className="flex flex-col text-left flex-1 sm:min-w-[400px]">
          <h3
            className={`text-[#061C3D] text-[32px] sm:text-[42px] font-[600] font-Inter`}
          >
            Why choose Us?
          </h3>

          {list.map((item, index) => {
            return (
              <div key={index} className="flex gap-2 my-[15px]">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M12.5 0C5.88293 0 0.5 5.38293 0.5 12C0.5 18.6171 5.88293 24 12.5 24C19.1171 24 24.5 18.6171 24.5 12C24.5 5.38293 19.1171 0 12.5 0Z"
                      fill="#168004"
                    />
                    <path
                      d="M18.582 9.45703L12.082 15.9569C11.887 16.1519 11.631 16.2501 11.375 16.2501C11.119 16.2501 10.863 16.1519 10.668 15.9569L7.41809 12.707C7.02698 12.316 7.02698 11.684 7.41809 11.293C7.80902 10.9019 8.44092 10.9019 8.83203 11.293L11.375 13.836L17.1681 8.04309C17.559 7.65198 18.1909 7.65198 18.582 8.04309C18.973 8.43402 18.973 9.06592 18.582 9.45703Z"
                      fill="#168004"
                    />
                  </svg>
                </span>
                <p className="text-[#454545] text-[16px] font-Inter font-[400]">
                  <span className="mr-1 font-[600]">{item.text}</span>
                  {item.paragraph}
                </p>
              </div>
            );
          })}

          <button className="text-[#168004] mt-3 text-[16px] font-Poppins font-[600] bg-[#F7F7F7] px-[25px] py-[12px] flex items-center justify-center gap-2 max-w-[167px] rounded-[10px]">
            Get Started
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
            >
              <path
                d="M15.9132 10.0926L2.02182 10.0926C1.73566 10.0926 1.48203 9.98201 1.26091 9.7609C1.03979 9.53978 0.929236 9.28614 0.929236 8.99999C0.929236 8.71384 1.03979 8.46021 1.26091 8.23909C1.48203 8.01797 1.73566 7.90741 2.02182 7.90741L15.9132 7.90741L9.98204 1.97626C9.76093 1.75514 9.65037 1.49501 9.65037 1.19585C9.65037 0.896688 9.76093 0.63655 9.98204 0.415432C10.2032 0.194315 10.4633 0.083756 10.7625 0.083756C11.0616 0.083756 11.3218 0.194315 11.5429 0.415432L19.347 8.21958C19.5681 8.4407 19.6787 8.70083 19.6787 8.99999C19.6787 9.29915 19.5681 9.55929 19.347 9.78041L11.5429 17.5846C11.3218 17.8057 11.0616 17.9162 10.7625 17.9162C10.4633 17.9162 10.2032 17.8057 9.98204 17.5846C9.76093 17.3634 9.65037 17.1033 9.65037 16.8041C9.65037 16.505 9.76093 16.2448 9.98204 16.0237L15.9132 10.0926Z"
                fill="#168004"
              />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Section6;
