import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './useCountDown';

const ShowCounter = ({ days, hours, minutes }) => {
    return (
        <div className='flex flex-row space-x-4'>
            <DateTimeDisplay value={days} type={'Days'}  />
            <DateTimeDisplay value={hours} type={'Hours'}/>
            <DateTimeDisplay value={minutes} type={'Mins'} />
        </div>
    );
};

const CountDownTimer = ({ targetDate }) => {
    const [days, hours, minutes] = useCountdown(targetDate);

    return (
        <ShowCounter
            days={days}
            hours={hours}
            minutes={minutes}
        />
    );
};

export default CountDownTimer;
