import React, { useContext, useEffect, useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import Switch from "@mui/material/Switch";
import {
  Box,
  CircularProgress,
  MenuItem,
  Modal,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { DndProvider, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableChart from "../../components/DraggableChart";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Multiselect from "multiselect-react-dropdown";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SensorImage from "../../assets/images/image.svg";

// widgets
import bar_icon from "../../assets/images/bar-icon.svg";
import line_icon from "../../assets/images/line-icon.svg";
import no_boards from "../../assets/images/no-boards.svg";
import filter_icon from "../../assets/icons/filter-icon.svg";

import "./Dashboard.css";
import { useSelector } from "react-redux";
import { useUser } from "../../state/UserContext";
import axios from "axios";
import { ThemeContext } from "../../state/ThemeContext";
import GeneralButton from "../../components/Button";
import IOSSwitch from "../../components/IosSwitch";
import CustomSnackbar from "../../components/CustomSnackbar";
import { useParams } from "react-router";
import { getBoardById } from "../../services/boardService";
import humidity_icon from "../../assets/images/humidity-sensor.svg";
import temperature_icon from "../../assets/images/temperature-sensor.svg";
import pressure_icon from "../../assets/images/pressure.svg";
import orientation_icon from "../../assets/images/orientation-sensor.svg";
import motion_icon from "../../assets/images/motion-sensor.svg";
import uv_icon from "../../assets/images/uv-sensor.svg";
import accelerometer_icon from "../../assets/images/accelerometer-sensor.svg";
import altitude_icon from "../../assets/images/altitude-sensor.svg";
import home_icon from "../../assets/images/home-icon.svg";
import light_icon from "../../assets/icons/lightIcon.svg";
const colorPickerStyle = {
  WebkitAppearance: "none", // Disable default appearance (for Chrome and Safari)
  MozAppearance: "none", // Disable default appearance (for Firefox)
  appearance: "none", // Disable default appearance (for modern browsers)
  padding: 0, // Remove padding
  border: "none", // Remove border
  width: "30px", // Set width
  height: "30px", // Set height
  background: "#F7F7F7", // Set default color
  borderRadius: "50%", // Make it a circle
  cursor: "pointer", // Show pointer cursor on hover
};
const LoadingIndicator = () => (
  <div className="flex justify-center items-center">
    <CircularProgress
      style={{ color: localStorage.getItem("theme-color") || "#168004" }}
    />
  </div>
);
const DropdownImage = ({ onClick }) => (
  <img
    src={filter_icon}
    alt="Dropdown"
    className="w-10 h-10 cursor-pointer"
    onClick={onClick}
  />
);
const Slide = ({ sensor, board, sensors, userData }) => {
  const { theme } = useContext(ThemeContext);
  //   const handleSwitchToggle = async (sensorId) => {
  //     try {
  //         const updatedSensors = sensors.map((sensor) =>
  //             sensor.id === sensorId
  //                 ? { ...sensor, is_realtime: !sensor.is_realtime }
  //                 : sensor
  //         );
  //         setSenos(updatedSensors);
  //         await axios.put(`${process.env.REACT_APP_SERVER_URL}/sensors/${sensorId}`, {
  //             is_realtime: updatedSensors.find((sensor) => sensor.id === sensorId)
  //                 .is_realtime,
  //         });
  //     } catch (error) {
  //         console.error("Error updating board status:", error);
  //     }
  // };
  return (
    <div className={`rounded-[20px] shadow-md p-5 m-2 ${theme === "dark" ? "text-white bg-[#4A4A4A]" : "text-black"}`}>
      <div className="flex justify-between">
        <div className="p-2 bg-[#F4F5FB] rounded-[10px]">
          {sensor.sensor_type === 'temperature' && (
            <img src={temperature_icon} className='w-12 h-12' alt="Temperature" />
          )}
          {sensor.sensor_type === 'humidity' && (
            <img src={humidity_icon} className='w-12 h-12' alt="Humidity" />
          )}
          {sensor.sensor_type === 'accelerometer' && (
            <img src={accelerometer_icon} className='w-12 h-12' alt="accelerometer" />
          )}
          {sensor.sensor_type === 'uv' && (
            <img src={uv_icon} className='w-12 h-12' alt="uv" />
          )}
          {sensor.sensor_type === 'motion' && (
            <img src={motion_icon} className='w-12 h-12' alt="motion" />
          )}
          {sensor.sensor_type === 'altitude' && (
            <img src={altitude_icon} className='w-12 h-12' alt="altitude" />
          )}
          {sensor.sensor_type === 'pressure' && (
            <img src={pressure_icon} className='w-12 h-12' alt="pressure" />
          )}
          {sensor.sensor_type === 'orientation' && (
            <img src={orientation_icon} className='w-12 h-12' alt="orientation" />
          )}
          {sensor.sensor_type === 'light' && (
            <img src={light_icon} className='w-12 h-12' alt="light" />
          )}
        </div>
        {/* <IOSSwitch sx={{ m: 1 }}
          checked={sensor.is_realtime}
          checkedColor="#168004"
        /> */}
        <div
          className={`text-[18px] font-[700]`}
          style={{ color: localStorage.getItem("theme-color") || userData.themeColor }}


        >{sensor.is_realtime ? 'ON' : 'OFF'}</div>
      </div>
      <div className="mt-8">
        <p className={`text-[18px] font-[700]   ${theme === "dark" ? "text-white " : "text-[#453838]"}`}>{sensor.name}</p>
        {/* <div className="flex justify-between mt-2">
        <span className={`text-[14px] font-[400] ${theme === "dark" ? "text-white " : "text-[#000]"}`}>
          Active for {sensor.activeHours} hours 
        </span>
        <span className="text-[#168004] text-[14px] font-[600]">{sensor.energyConsumption}Kwh</span> 
      </div> */}
      </div>
    </div>

  );
};

const Dashboard = ({ selectedThemeColor }) => {
  const [boards, setBoards] = useState([]);
  // const [sensors, setSensors] = useState([]);
  const [board, setBoard] = useState();
  const [userWidgets, setUserWidgets] = useState([]);
  const { userData } = useUser();
  const [sensorData, setSensorData] = useState({});
  const [sensorDataValue, setSensorDataValue] = useState({});
  const [defaultBoardId, setDefaultBoardId] = useState("");
  const [sensorType, setSensorType] = useState("");
  const [charts, setCharts] = useState([]);
  const theme = useContext(ThemeContext).theme;
  const [filteredCharts, setFilteredCharts] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [widgetSnackbar, setWidgetSnackbar] = useState(false);
  const [selectedBoards, setSelectedBoards] = useState([]);
  const [boardSensors, setBoardSensors] = useState([]);
  const { boardId } = useParams();
  const { dashboardId } = useParams();

  const [boardData, setBoardData] = useState([]);
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

  };
  useEffect(() => {
    // Filter charts based on selected boards
    const chartsBySelectedBoards = userWidgets.filter((widget) =>
      selectedBoards.some((selectedBoard) => widget.board == selectedBoard)
    );
    setFilteredCharts(chartsBySelectedBoards);
  }, [selectedBoards]);

  const [isBoardListOpen, setIsBoardListOpen] = useState(false);
  useEffect(() => {
    setFilteredCharts(charts);
  }, [charts]);
  const toggleBoardList = () => {
    setIsBoardListOpen(!isBoardListOpen);
  };
  const moveCard = (fromIndex, toIndex) => {
    if (fromIndex === toIndex) {
      return;
    }
    const updatedCharts = [...charts];
    const [movedChart] = updatedCharts.splice(fromIndex, 1);
    updatedCharts.splice(toIndex, 0, movedChart);
    setCharts(updatedCharts);
  };

  const [sensor, setSensor] = useState("");
  const [color, setColor] = useState(
    localStorage.getItem("theme-color") || "#168004"
  );

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);
  const fetchSensorData = async () => {
    try {
      if (sensor === "") {
        return;
      }
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token

      };
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/sensors/sensor-data/${sensor}`, { headers }
      );
      if (response.status == 200) {
        setSensorData(response.data);
        setSensorDataValue(JSON.parse(response.data.value));
        setSensorType(response.data.sensor_type);
      } else {
        console.error("Failed to fetch sensor data");
      }
    } catch (error) {
      console.error("Error fetching sensor data:", error);
    }
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const onSelect = (selectedList) => {
    if (selectedList.some((item) => item.id === -1)) {
      setSelectedBoards(boards.map((board) => board.id));
      setSelectedOptions(boards);
    } else {
      setSelectedBoards(selectedList.map((board) => board.id));
      setSelectedOptions(selectedList);
    }
  };

  const onRemove = (selectedList) => {
    setSelectedBoards(selectedList.map((board) => board.id));
    setSelectedOptions(selectedList);
  };

  const fetchUserWidgetsByDashboard = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token

      };
      const url = `${process.env.REACT_APP_SERVER_URL}/widgets/by-dashboard/${dashboardId}`;
      const response = await axios.get(url, { headers });
      const responseWidgets = response.data.widgets;
      if (responseWidgets.length > 0) {
        setUserWidgets(responseWidgets);
        setCharts(userWidgets);
      }
    } catch (error) {
      console.error("Error fetching user widgets:", error);
    }
  };

  useEffect(() => {
    fetchUserWidgetsByDashboard();
  }, [userData.id, boards]);
  const fetchBoards = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token

      };
      //get user's boards list
      const url = `${process.env.REACT_APP_SERVER_URL}/boards/by-user/${userData.id}`;
      const response = await axios.get(url, { headers });
      const responseBoards = response.data.boards;
      if (responseBoards.length > 0) {
        setBoards(responseBoards);
        const defaultId = responseBoards[0].id;
        setDefaultBoardId(defaultId);
        setBoard(defaultId);
      }
    } catch (error) {
      console.error("Error fetching boards:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchBoardSensors = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token

      };
      if (!boardId) return;
      const url = `${process.env.REACT_APP_SERVER_URL}/sensors/by-board/${boardId}`;
      const response = await axios.get(url, { headers });
      const responseSensors = response.data;
      if (responseSensors.length > 0) {
        setBoardSensors(responseSensors);
        const firstSensor = responseSensors[0];
        setSensor(firstSensor.id);
        setSensorType(firstSensor.sensor_type)
      }
    } catch (error) {
      console.error("Error fetching sensors:", error);
    }
  };

  useEffect(() => {
    const fetchBoardData = async () => {
      try {
        // setLoading(true);
        const data = await getBoardById(boardId);
        setBoardData(data);
        // setLoading(false);
      } catch (error) {
        // setError(error);
        // setLoading(false);
      }
    };

    fetchBoardData();
  }, [boardId]);
  useEffect(() => {
    fetchBoards();
  }, []);

  useEffect(() => {
    fetchBoardSensors();
  }, [board, sensor, sensorType]);

  const handleSwitchToggle = async (boardId) => {
    try {
      const updatedBoards = boards.map((board) =>
        board.id === boardId
          ? { ...board, is_realtime: !board.is_realtime }
          : board
      );
      setBoards(updatedBoards);
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token

      };
      await axios.put(`${process.env.REACT_APP_SERVER_URL}/boards/${boardId}`, {
        is_realtime: updatedBoards.find((board) => board.id === boardId)
          .is_realtime,
      }, { headers });
    } catch (error) {
      console.error("Error updating board status:", error);
    }
  };

  const getBoardNameById = (sensorId) => {
    const sensor = boardSensors.find((item) => item.id === sensorId);
    // const board = boards.find((item) => item.id === sensor.board);
    return board ? board.name : null;
  };

  useEffect(() => {
    fetchSensorData();
  }, [defaultBoardId, sensor]);

  const saveWidgetToDatabase = async (widgetData) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token

      };
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/widgets`,
        { widget: widgetData }
        , { headers });
      return response.data.widgetId;
    } catch (error) {
      console.error("Error saving widget data:", error);
    }
  };
  const handleSubmit = async (e, chartType) => {
    const userId = userData.id;
    e.preventDefault();
    const newChart = {
      type: chartType,
      sensor: sensor,
      userId,
      board: boardData.id,
      dashboardId: dashboardId,
      color,
      minValue,
      maxValue,
      created_at: (new Date().toISOString())
    };
    setOpenModal(false);

    try {
      const widgetId = await saveWidgetToDatabase(newChart);
      const updatedChart = { ...newChart, id: widgetId };
      addChart(updatedChart);
      setWidgetSnackbar(true);
    } catch (error) {
      console.error("Error saving widget to database:", error);
    }
  };

  const addChart = (newChart) => {
    setCharts([...charts, newChart]);
  };

  const [selectedColor, setSelectedColor] = useState("#0000ff");

  const handleColorChange = (e) => {
    setSelectedColor(e.target.value);
    setColor(e.target.value);
  };
  const [addedComponents, setAddedComponents] = useState([]);
  const [open, setOpen] = useState(false);
  const [openWidgets, setOpenWidgets] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const widgets = [
    
    {
      img: bar_icon,
      text: "bar chart",
    },
    {
      img: line_icon,
      text: "line chart",
    },

  ];
  const [openWidgetDrawer, setOpenWidgetDrawer] = useState(false);

  const handleOpenWidgetDrawer = () => {
    if (
      sensorType === "motion" ||
      sensorType === "uv" ||
      sensorType === "light" ||
      sensorType === "orientation" ||
      sensorType === "accelerometer"
    ) {
      // For these sensors , allow only bar and line charts
      const filtered = widgets.filter(
        (widget) =>
          widget.text.toLocaleLowerCase() === "bar chart" ||
          widget.text.toLocaleLowerCase() === "line chart"
      );
      setFilteredWidgets(filtered);
    } else if (
      sensorType === "pressure" ||
      sensorType === "altitude" ||
      sensorType === "temperature" ||
      sensorType === "humidity"
    ) {
      const filtered = widgets.filter(
        (widget) =>
          widget.text.toLowerCase() === "bar chart" ||
          widget.text.toLowerCase() === "line chart"
      );
      setFilteredWidgets(filtered);
    }
    setOpenModal(false);
    setOpenWidgetDrawer(true);
  };

  const handleCloseWidgetDrawer = () => {
    setOpenWidgetDrawer(false);
  };

  const [searchText, setSearchText] = useState("");
  const [filteredWidgets, setFilteredWidgets] = useState(widgets);

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchText(searchTerm);

    const filtered = widgets.filter((widget) =>
      widget.text.toLowerCase().includes(searchTerm)
    );

    setFilteredWidgets(filtered);
  };

  // Edit and Delete widgets
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingCardData, setEditingCardData] = useState(null);

  const handleDelete = (cardData) => {
    const updatedCharts = charts.filter((chart) => chart !== cardData);
    setCharts(updatedCharts);
  };

  const handleEdit = (cardData) => {
    setEditingCardData(cardData);
    setEditModalOpen(true);
  };

  // Modal edit
  const widget = useSelector((state) => state.sensy.selectedWidget);

  const [chartTypeUpdate, setChartTypeUpdate] = useState(widget?.type || "");
  const [sensorUpdate, setSensorUpdate] = useState(widget?.sensor || "");
  const [colorUpdate, setColorUpdate] = useState(widget?.color || "");
  const [minValueUpdate, setMinValueUpdate] = useState(widget?.minValue || 0);
  const [maxValueUpdate, setMaxValueUpdate] = useState(widget?.maxValue || 60);

  const [typeWidget, setTypeWidget] = useState();

  const handleSubmitUpdate = (e) => {
    e.preventDefault();
    const updatedChart = {
      type: widget?.type,
      sensor: widget?.sensor,
      board: widget?.board,
      color: colorUpdate,
      minValue: minValueUpdate,
      maxValue: maxValueUpdate,
    };

    // Find the index of the chart to be updated
    const chartIndex = charts.findIndex((chart) => chart === widget);

    // Create a new array with the updated chart at the specific index
    const updatedCharts = [...charts];
    updatedCharts[chartIndex] = updatedChart;

    // Set the new array as the state
    setCharts(updatedCharts);

    setEditModalOpen(false);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setEditingCardData(null);
  };

  const handleSaveEdit = (editedData) => {
    handleEditModalClose();
  };

  return (
    <div className="mx-[32px] mt-[36px]">
      {boards.length > 0 &&
        // <div className="shadow-md flex justify-end items-end py-[13px] px-[22px]">
        //   <button
        //     style={{ backgroundColor: selectedThemeColor }}
        //     className=" bg-${UserThemeColor} flex sm:gap-5 gap-3 text-dark_green sm:text-[17px] text-[15px] rounded-[10px] sm:pl-[20px] pl-[15px] sm:pr-[25px] pr-[20px] py-[10px] sm:py-[10px] sm:h-[47px] h-[45px] transition-all duration-75 active:scale-90"
        //     type="button"
        //   >
        //     {/* <img src={preview} alt="eye" />
        //   Preview */}
        //   </button>
        //   {boards.length > 0 && (
        //     <div className="sm:max-w-[300px]">
        //       <GeneralButton
        //         onClick={() => {
        //           setOpenModal(true);
        //         }}
        //         backgroundColor={
        //           localStorage.getItem("theme-color") || userData.themeColor
        //         }
        //       >
        //         Add Widget
        //       </GeneralButton></div>
        //   )}
        // </div>
        <div className="flex flex-col lg:flex-row  justify-between">
          <div className='flex flex-col md:flex-row items-start'>
            <h2 className={`text-[25px] font-[600] ${theme === "dark" ? "text-white" : "text-black"}`}>
              {boardData.name}
            </h2>
          </div>
          <div className='flex md:items-end md:justify-end'>
            <GeneralButton
              onClick={() => {
                setOpenModal(true);
              }}
              backgroundColor={
                localStorage.getItem("theme-color") || userData.themeColor
              }
              className="mt-8 md:mt-0"
            >
              Add Widget
            </GeneralButton>
          </div>

        </div>

      }
      <section className="rounded-lg flex flex-col mt-[30px]">
        {/* <div>
          {boards.length > 0 &&
            <h2
              className={`text-[30px] font-[600]  ${theme === "dark" ? "text-white" : "text-black"
                }`}
            >
              Boards
            </h2>
          }
          {isLoading || boards.length > 0 ? (
            <div className="flex items-center justify-end relative">
              <Multiselect
                options={[{ id: -1, name: "Select All" }, ...boards]}
                selectedValues={selectedOptions}
                onSelect={onSelect}
                onRemove={onRemove}
                placeholder="Filter Boards"
                displayValue="name"
                className="filter-container"
                style={{
                  option: { backgroundColor: "white", color: "black" },
                  optionHover: { backgroundColor: "#000000" },
                  chips: {
                    background: "#168004",
                  },
                }}
              />
            </div>
          ) : (
            <div className="xs:text-[20px] text-[15px] font-semibold flex flex-col items-center justify-center text-center text-[#BEC8D0]">
              <img src={no_boards} alt="no boards" />
              <span>
                Start customizing your dashboard by adding or rearranging widgets.<br /> Your sensor data, your way.
              </span>
            </div>
          )}
        </div> */}
        {isLoading ? <LoadingIndicator /> : null}

        <div className='flex justify-between mt-8'>
          {!isLoading &&
            <div className={`text-[22px] ml-2 font-[600] mt-4 ${theme === "dark" ? "text-white" : "text-[#000]"}`}>
              My Sensors
            </div>}
          {boardSensors.length > 0 &&
            <div className='mt-4 space-x-2 mr-2'>
              <button
                className="cursor-pointer rounded-[10px] p-4 bg-[#EDEEF4]"
                onClick={previous}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.37274 0.244292L0.209416 5.5927C-0.0502742 5.81784 -0.0502742 6.18157 0.209416 6.4073L6.37274 11.7557C6.74762 12.0814 7.35756 12.0814 7.73312 11.7557C8.108 11.43 8.108 10.9013 7.73312 10.5756L2.46073 5.99971L7.73312 1.42496C8.108 1.09866 8.108 0.570015 7.73312 0.244292C7.35756 -0.0814304 6.74762 -0.0814304 6.37274 0.244292Z" fill="#242424" />
                </svg>
              </button>
              <button
                className="    cursor-pointer rounded-[10px] p-4"
                style={{ backgroundColor: localStorage.getItem("theme-color") || userData.themeColor }}
                onClick={next}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.65656 0.244292L7.81988 5.5927C8.07957 5.81784 8.07957 6.18157 7.81988 6.4073L1.65656 11.7557C1.28167 12.0814 0.671732 12.0814 0.29618 11.7557C-0.0787067 11.43 -0.0787067 10.9013 0.29618 10.5756L5.56856 5.99971L0.29618 1.42496C-0.0787067 1.09866 -0.0787067 0.570015 0.29618 0.244292C0.671732 -0.0814304 1.28167 -0.0814304 1.65656 0.244292Z" fill="white" />
                </svg>
              </button>
            </div>}
        </div>
        {boardSensors.length == 0 && !isLoading && <p className={`flex text-[18px] font-[600] items-center justify-center`}
          style={{
            color:
              localStorage.getItem("theme-color") ||
              userData.themeColor,
          }}
        >No sensors found.</p>}
        <Slider{...settings} ref={(slider) => {
          sliderRef = slider;
        }}>
          {boardSensors.map(sensor => (
            <Slide key={sensor.id} sensor={sensor} board={boardData} sensors={boardSensors} userData={userData} />
          ))}
        </Slider>
        {/* {!isLoading && boards.length > 0 ?
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-8">
            {boards && boards.map((board, index) => (
              <div key={index} className="board-container rounded-lg bg-black w-full p-4 grid grid-cols-2">
                <div className="flex justify-start p-2">
                  <div className="board-icon  shadow-md p-2 rounded-full">
                    <img className="boardIcon" src={board.logo} alt={board.name} />
                  </div>
                </div>
                <div className="flex justify-end p-2 mt-2">
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={board.is_realtime}
                    onChange={() => handleSwitchToggle(board.id)}
                  />
                </div>
                <div className="flex justify-start p-2 dark:text-white">{board.name}</div>
                <div className="flex justify-end p-2 dark:text-white">
                  {board.is_realtime ? <span>Realtime</span> : <span>Off</span>}
                </div>
              </div>
            ))}
          </div> : null} */}

        {boards.length > 0 ? (
          <h2
            className={`text-[22px] ml-2 font-[600] mt-4 ${theme === "dark" ? "text-white" : "text-[#000]"}`}
          >
            My Widgets
          </h2>
        ) : null}

        {userWidgets.length == 0 && !isLoading && <p className={`flex text-[18px] font-[600] items-center justify-center`}
          style={{
            color:
              localStorage.getItem("theme-color") ||
              userData.themeColor,
          }}
        > No widgets found. </p>}
        <DndProvider backend={HTML5Backend}>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
            {filteredCharts &&
              filteredCharts.map((chart, index) => (
                <div key={chart && chart.id} className="flex items-center w-full">
                  {chart && (
                    <DraggableChart
                      key={chart.id}
                      chart={chart}
                      index={index}
                      boardName={getBoardNameById(sensor)}
                      sensor={chart && chart.sensor}
                      onCardMove={moveCard}
                      onEdit={() => handleEdit(chart)}
                      onDelete={() => handleDelete(chart)}
                    />
                  )}
                </div>
              ))}
          </div>
        </DndProvider>
        <Dialog
          open={openWidgetDrawer}
          onClose={handleCloseWidgetDrawer}
          fullWidth
          maxWidth="sm"
        >
          <div className="my-5 rounded-[8px]">
            <div className="p-5">
              <TextField
                className="mt-4 w-full search-filed "
                label="Search Widgets"
                // variant="outlined"
                onChange={handleSearchChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <List>
              <div className=" w-full px-4 grid grid-cols-2 gap-4">
                {filteredWidgets &&
                  filteredWidgets.map((widget, index) => (
                    <ListItem
                      key={index}
                      button
                      onClick={(e) => {
                        {
                          handleCloseWidgetDrawer();
                          setTimeout(() => {
                            handleSubmit(e, widget.text.split(" ")[0]);
                          }, 300);
                        }
                      }}
                    >
                      <img
                        className="mr-4"
                        src={widget.img}
                        alt={widget.text}
                        width="40"
                        height="40"
                      />
                      <ListItemText
                        className="boardIcon"
                        primary={widget.text}
                      />
                    </ListItem>
                  ))}
              </div>
            </List>
          </div>
        </Dialog>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            className={`fixed  lg:w-[35%] sm:w-[90%] sm:p-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96  rounded-[16px] shadow-lg p-8  ${theme === "dark" ? "bg-[#1E1E1E]" : "bg-[#FFFFFF]"
              }`}
          >
            <h1
              className={` ${theme === "dark" ? "text-white" : "text-black"
                } p-4 font-[700] text-[28px]`}
              variant="h6"
              id="modal-modal-title"
            >
              Add Widget
            </h1>
            <form className="p-4">
              <div clasName="mt-3">
                <label
                  className={`my-3 text-[15px] font-[500] ${theme === "dark" ? "text-white" : "text-black"
                    }`}
                >
                  Choose sensor
                </label>
                <select
                  value={sensor}
                  onChange={(e) => {
                    const selectedSensorId = e.target.value;
                    const selectedSensor = boardSensors.find(
                      (sensor) => sensor.id === selectedSensorId
                    );
                    if (selectedSensor) {
                      setSensor(selectedSensorId);
                      setSensorType(selectedSensor.sensor_type);
                    }
                  }}
                  className={`rounded-[8px] mt-3 px-3 focus:outline-none h-[50px] ${theme === "dark" ? "bg-[#4A4A4A] text-white border-none" : "bg-white border border-[#D0D5DD]"}`}>

                  {boardSensors &&
                    boardSensors.map((sensor) => (
                      <option key={sensor.id} value={sensor.id}>
                        {sensor.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-1 flex justify-items">
                <label
                  className={`mr-4 mt-4 text-[15px] font-[500] ${theme === "dark" ? "text-white" : "text-black"
                    }`}
                >
                  Color Pick
                </label>
                <input
                  className={`mt-3 ${theme === "dark"
                    ? "bg-[#4A4A4A]"
                    : "bg-[#F7FBF7] border border-[#168004]"
                    } `}
                  type="color"
                  id="colorstyle"
                  style={colorPickerStyle}
                  value={color}
                  onChange={handleColorChange}
                />
              </div>

              {/* <div className="mb-1">
                <label className={`my-3 text-[15px] font-[500] ${theme === "dark" ? "text-white" : "text-black"}`}>Minimum value</label>
                <input
                  type="text"
                  value={minValue}
                  onChange={(e) => setMinValue(e.target.value)}
                  className={`modal-input mt-2 p-2 w-full  rounded focus:ring focus:ring-emerald-500 focus:ring-opacity-50  ${theme === "dark" ? "bg-[#4A4A4A] text-white" : "bg-[#F7FBF7] border border-[#168004] text-black"} `}
                />
              </div>

              <div className="mb-1">
                <label className={`my-3 text-[15px] font-[500] ${theme === "dark" ? "text-white" : "text-black"}`}>Maximum value</label>
                <input
                  type="text"
                  value={maxValue}
                  onChange={(e) => setMaxValue(e.target.value)}
                  className={`modal-input mt-2 p-2 w-full  rounded focus:ring focus:ring-emerald-500 focus:ring-opacity-50  ${theme === "dark" ? "bg-[#4A4A4A] text-white" : "bg-[#F7FBF7] text-black  border border-[#168004]"} `}
                />
              </div> */}

              <div className="flex justify-between">
                <div>
                  {/* <button
                    type="submit"
                    className="mt-4 rounded-md text-white bg-[#168004] p-3 justify-center items-center"
                  >
                    Test Connection
                  </button> */}
                </div>
                <div className="space-x-2">
                  <button
                    className="mt-4 rounded-md bg-[#D9D9D9] p-3 justify-center items-center mr-2 w-28"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="mt-4 rounded-md bg-[#168004] text-white p-3 justify-center items-center w-28"
                    onClick={() => {
                      handleOpenWidgetDrawer();
                    }}
                    style={{
                      backgroundColor:
                        localStorage.getItem("theme-color") ||
                        userData.themeColor,
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
        <CustomSnackbar
          openSnackbar={widgetSnackbar}
          closeSnackbar={() => {
            setWidgetSnackbar(false);
          }}
          message="Widget added successfully"
        />
      </section>
    </div>
  );
};

export default Dashboard;
