import React from 'react';
import plus_icon from "../assets/images/plus-icon.svg";

const GeneralButton = ({ onClick, backgroundColor, children }) => {
  return (
    <button
      className="flex md:flex  md:mt-0 items-center justify-center gap-1 bg-dark_green text-white rounded-[10px] sm:text-[16px] text-[15px] font-[600] px-[30px] py-[2px] sm:h-[45px] h-[45px] transition-all duration-75 active:scale-90 flex-grow" 
      type="button"
      onClick={onClick}
      style={{ backgroundColor }} 
    >
      <span>{children}</span>
      <img src={plus_icon} alt="plus icon"  />
    </button>
  );
};

export default GeneralButton;
