import React from "react";
import styles, { typo } from "../../../style";
import "./section5.css";

const Section5 = () => {
  const spectrum = [
    {
      icon: (
        <div className="icon-container icon-container1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="43"
            viewBox="0 0 42 43"
            fill="none"
          >
            <path
              opacity="0.2"
              d="M11.4351 36.3148C11.313 36.4389 11.1674 36.5373 11.0069 36.6046C10.8463 36.6718 10.674 36.7064 10.5 36.7064C10.3259 36.7064 10.1536 36.6718 9.99307 36.6046C9.83252 36.5373 9.68695 36.4389 9.56482 36.3148L6.18513 32.9352C6.06112 32.813 5.96264 32.6675 5.89542 32.5069C5.8282 32.3464 5.79358 32.1741 5.79358 32C5.79358 31.8259 5.8282 31.6536 5.89542 31.4931C5.96264 31.3325 6.06112 31.187 6.18513 31.0648L23.625 13.625L28.875 18.875L11.4351 36.3148Z"
              fill="#FF6250"
            />
            <path
              d="M35.4375 21.5V29.375"
              stroke="#FF6250"
              strokeWidth="2.375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M31.5 25.4375H39.375"
              stroke="#FF6250"
              strokeWidth="2.375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.7812 7.0625V13.625"
              stroke="#FF6250"
              strokeWidth="2.375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.5 10.3438H17.0625"
              stroke="#FF6250"
              strokeWidth="2.375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M27.5625 30.6875V35.9375"
              stroke="#FF6250"
              strokeWidth="2.375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.9375 33.3125H30.1875"
              stroke="#FF6250"
              strokeWidth="2.375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M30.5855 6.67386L6.1887 31.0707C5.67613 31.5833 5.67613 32.4143 6.1887 32.9269L9.58198 36.3201C10.0945 36.8327 10.9256 36.8327 11.4381 36.3201L35.835 11.9233C36.3475 11.4107 36.3475 10.5797 35.835 10.0671L32.4417 6.67386C31.9291 6.1613 31.0981 6.1613 30.5855 6.67386Z"
              stroke="#FF6250"
              strokeWidth="2.375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.625 13.625L28.875 18.875"
              stroke="#FF6250"
              strokeWidth="2.375"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
      title: "For Hobbyists and Makers",
      text: "Dive into a world of creativity with Sensy32. Its IR Motion and Human Presence Sensor and Light Intensity Sensor open up new avenues for interactive installations and smart home applications.",
    },
    {
      icon: (
        <div className="icon-container icon-container2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="45"
            viewBox="0 0 46 45"
            fill="none"
          >
            <g clipPath="url(#clip0_1566_14546)">
              <path
                d="M38.8864 21.9339C38.0265 24.0097 37.9724 26.576 38.8323 28.6519L39.5722 30.1013H7.63355C7.63355 30.1013 1.68719 26.7602 7.63355 20.8139L39.2965 20.1614L38.8864 21.9339Z"
                fill="#F1F2F2"
              />
              <path
                d="M39.5723 30.1017H7.63371C7.63371 30.1017 1.68754 26.7603 7.63371 20.8142L39.2968 20.1614L38.8862 21.9342C38.6962 22.3943 38.545 22.8779 38.4351 23.3749H9.4534C8.33061 23.3749 7.41762 24.2879 7.41762 25.4097C7.41762 26.5325 8.33061 27.4455 9.4534 27.4455H38.4459C38.5432 27.8606 38.6719 28.2649 38.8322 28.6521L39.5723 30.1017Z"
                fill="#DEE1E1"
              />
              <path
                d="M39.0888 31.4574L9.89462 31.4113C6.39705 31.4113 3.39336 29.1802 3.39336 25.6826C3.39336 24.9101 3.25884 23.8971 3.51212 23.2127C4.40539 20.7981 6.7284 19.0769 9.45352 19.0769H39.404C40.1929 19.0769 40.8324 19.7165 40.8324 20.5054C40.8324 21.2943 40.1929 21.9338 39.404 21.9338H16.7367L14.523 22.4535L12.5978 21.9338H9.45343C7.53371 21.9338 5.97747 23.4901 5.97747 25.4098C5.97747 27.3295 7.53371 28.8857 9.45343 28.8857H39.4039C40.1928 28.8857 40.8324 29.5253 40.8324 30.3142C40.8324 30.3711 40.829 30.4272 40.8225 30.4824C40.7393 31.1921 39.8209 31.4574 39.0888 31.4574Z"
                fill="#B7E546"
              />
              <path
                d="M40.8221 30.4825C40.7834 30.8093 40.6348 31.1028 40.4142 31.3243C40.1549 31.5827 39.7984 31.743 39.404 31.743L24.6073 32.6298L9.45346 31.743C5.95544 31.743 3.12012 28.9077 3.12012 25.4097C3.12012 24.4976 3.3128 23.6305 3.66035 22.8472V24.1491C3.66035 27.6471 6.49568 30.4825 9.99369 30.4825H40.8221Z"
                fill="#ABD641"
              />
              <path
                d="M16.7365 26.0985V21.9341H13.7683L12.7144 22.0399L12.7777 25.9184C12.7777 26.0453 12.9052 26.1323 13.0234 26.0861L13.7682 25.904L14.6014 25.5778C14.6436 25.5612 14.6905 25.5612 14.7327 25.5778L16.4908 26.2662C16.6089 26.3124 16.7365 26.2253 16.7365 26.0985Z"
                fill="#EF5227"
              />
              <path
                d="M13.7684 21.9343V25.9041L12.8437 26.2661C12.7258 26.312 12.5979 26.2256 12.5979 26.0986V21.9343H13.7684Z"
                fill="#D83724"
              />
              <path
                d="M34.7813 15.4405V17.4709L34.6913 18.3712C34.6913 18.7197 34.4086 19.0015 34.061 19.0015L10.0647 18.9115C9.71629 18.9115 9.43447 18.6297 9.43447 18.2812L9.25439 15.4405L21.5729 14.8518L34.7813 15.4405Z"
                fill="#FDCD08"
              />
              <path
                d="M42.1746 15.5832C41.8015 15.5832 41.4993 15.2809 41.4993 14.9079V7.90308C41.4993 7.53013 41.8015 7.22778 42.1746 7.22778C42.5476 7.22778 42.8499 7.53013 42.8499 7.90308V14.9078C42.8499 15.2809 42.5475 15.5832 42.1746 15.5832Z"
                fill="#77777F"
              />
              <path
                d="M40.9675 17.1353C40.4039 18.5247 39.8367 19.8956 39.273 21.2851C38.9361 22.1156 39.5413 23.0257 40.4306 23.0257L41.4729 23.1157H44.0083C44.8976 23.1157 45.5029 22.2056 45.166 21.375L43.9905 18.4774L43.2512 17.1353L40.9675 17.1353Z"
                fill="#FDCD08"
              />
              <path
                d="M41.8752 18.9896C42.6161 19.0571 43.3931 18.8626 43.9905 18.4776L43.3584 16.8979L42.3139 17.2102L42.314 17.2099L40.9523 17.0282C40.7804 17.4523 40.5303 18.0545 40.3583 18.4786L40.3584 18.4787C39.9668 19.4442 39.575 20.4096 39.1833 21.3751C38.8466 22.2062 39.4517 23.1156 40.3403 23.1156H41.781C40.8923 23.1156 40.2872 22.2062 40.624 21.3751C40.9167 20.6541 41.2091 19.9332 41.5017 19.2121C41.5623 19.0625 41.7145 18.975 41.8752 18.9896Z"
                fill="#FAAB18"
              />
              <path
                d="M44.2529 15.5536C44.2529 14.6335 43.666 13.8517 42.8495 13.5688C42.6379 13.4954 42.3302 13.6259 42.0939 13.6259C40.946 13.6259 40.2395 14.395 40.2395 15.5537C40.2395 16.7124 41.0983 17.4076 42.2462 17.4076C42.4826 17.4076 42.6379 17.6118 42.8495 17.5385C43.666 17.2555 44.2529 16.4737 44.2529 15.5536Z"
                fill="#FDCD08"
              />
              <path
                d="M42.8496 17.5383C42.638 17.6122 42.4102 17.6518 42.1743 17.6518C41.0263 17.6518 40.0962 16.7118 40.0962 15.553C40.0962 14.3951 41.0263 13.4551 42.1743 13.4551C42.4102 13.4551 42.638 13.4947 42.8496 13.5685C42.0329 13.8512 41.4468 14.6337 41.4468 15.553C41.4468 16.4732 42.0329 17.2556 42.8496 17.5383Z"
                fill="#FAAB18"
              />
              <path
                d="M42.3407 8.62512L36.4019 10.6811L34.7813 10.5149L23.2537 15.2327C22.4529 15.5099 21.5823 15.5099 20.7816 15.2327L9.25423 10.6988L7.63353 10.6812L1.69429 8.62512C1.01629 8.39038 1.01629 7.43156 1.69429 7.19692L21.0067 0.511347C21.6615 0.284629 22.3735 0.284629 23.0283 0.511347L42.3407 7.19692C43.0187 7.43156 43.0187 8.39038 42.3407 8.62512Z"
                fill="#868491"
              />
              <path
                d="M36.4019 10.0715V10.6811L23.2535 15.2325C22.4531 15.5098 21.5824 15.5098 20.781 15.2325L7.63354 10.6811V10.0715C7.63354 8.78664 8.55104 7.68997 9.81609 7.46217C13.8354 6.74186 17.9403 6.3772 22.0173 6.3772C26.0942 6.3772 30.2 6.74186 34.2193 7.46217C34.8316 7.57291 35.3871 7.89525 35.7851 8.37156C36.1813 8.84426 36.4019 9.44933 36.4019 10.0715Z"
                fill="#77777F"
              />
              <path
                d="M34.7812 10.0716V15.4407H9.52437L9.43433 10.2517C9.43433 9.75291 9.79178 9.32612 10.2825 9.23788C10.5397 9.19178 10.6172 8.96714 10.8749 8.92401C14.5633 8.30715 18.2906 7.99805 22.0173 7.99805C26.0051 7.99805 29.9929 8.3519 33.933 9.0578C34.1779 9.10192 34.3904 9.23068 34.5408 9.41076C34.692 9.59084 34.7812 9.82224 34.7812 10.0716Z"
                fill="#868491"
              />
              <path
                d="M34.7813 17.4709V18.4613C34.7813 18.8097 34.4986 19.0916 34.1511 19.0916H9.88467C9.53658 19.0916 9.25439 18.8094 9.25439 18.4613V15.4405L10.0647 14.8518L10.8751 15.4405V17.0207C10.8751 17.2693 11.0767 17.4709 11.3253 17.4709H34.7813Z"
                fill="#FAAB18"
              />
              <path
                d="M10.8751 8.92456V15.4407H9.25439V10.0717C9.25439 9.57284 9.61185 9.14606 10.1026 9.05782C10.3601 9.0119 10.6176 8.96688 10.8751 8.92456Z"
                fill="#77777F"
              />
              <path
                d="M41.2656 41.2969C40.4057 39.2211 40.4057 36.8887 41.2656 34.8129L41.5396 33.5957H4.72155C4.72155 33.5957 0.576966 38.1015 4.99086 42.5153L41.7793 42.7912L41.2656 41.2969Z"
                fill="#F1F2F2"
              />
              <path
                d="M41.7793 42.7907L4.99115 42.5152C0.576541 38.1015 4.72193 33.5959 4.72193 33.5959L41.7166 33.6643L41.266 34.8124C41.0995 35.2139 40.9653 35.6245 40.8636 36.0405H7.07285C5.95006 36.0405 5.03707 36.9535 5.03707 38.0754C5.03707 39.1982 5.95006 40.1112 7.07285 40.1112H40.8744C40.9752 40.5127 41.1058 40.9098 41.266 41.297L41.7793 42.7907Z"
                fill="#DEE1E1"
              />
              <path
                d="M41.159 44.0937L7.87145 44.0325C4.37389 44.0325 1.29672 42.0587 1.29672 38.5612C1.29672 37.4512 1.02525 35.9227 1.52695 35.0155C2.60607 33.0644 4.68506 31.7432 7.07254 31.7432H41.7846C42.5735 31.7432 43.2131 32.3827 43.2131 33.1716C43.2131 33.9606 42.5735 34.6001 41.7846 34.6001H33.1841L30.9368 35.0976L29.0453 34.6001H7.07263C5.15291 34.6001 3.59667 36.1563 3.59667 38.0761C3.59667 39.9958 5.15291 41.552 7.07263 41.552H41.7846C42.5735 41.552 43.2131 42.1916 43.2131 42.9805C43.2131 43.0686 43.2051 43.1548 43.1899 43.2385C43.0684 43.9043 41.8599 44.0937 41.159 44.0937Z"
                fill="#EF5227"
              />
              <path
                d="M43.1902 43.2383C43.137 43.5301 42.9957 43.7894 42.7949 43.9902C42.5365 44.2486 42.179 44.4088 41.7847 44.4088H7.07284C3.57483 44.4088 0.739502 41.5735 0.739502 38.0755C0.739502 36.824 1.10236 35.6571 1.72993 34.6738V36.905C1.72993 40.403 4.56526 43.2383 8.06327 43.2383H43.1902Z"
                fill="#D83724"
              />
              <path
                d="M33.184 38.7967V34.5986H30.3957L29.1589 34.6739L29.2252 38.6166C29.2252 38.7435 29.3529 38.8306 29.4709 38.7843L30.3956 38.5318L31.0489 38.276C31.0912 38.2594 31.1381 38.2594 31.1802 38.276L32.9383 38.9644C33.0564 39.0106 33.184 38.9236 33.184 38.7967Z"
                fill="#FDCD08"
              />
              <path
                d="M30.3958 34.5981V38.5319L29.291 38.9641C29.173 39.0101 29.0452 38.9236 29.0452 38.7967V34.5981H30.3958Z"
                fill="#FAAB18"
              />
            </g>
            <defs>
              <clipPath id="clip0_1566_14546">
                <rect
                  width="44.5209"
                  height="44.5209"
                  fill="white"
                  transform="translate(0.739502 0.114502)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      ),
      title: "For Educators and Students",
      text: " Sensy32 is an educational powerhouse. It's a perfect tool for teaching and learning about IoT, robotics, and environmental monitoring.       ",
    },
    {
      icon: (
        <div className="icon-container icon-container3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="45"
            viewBox="0 0 46 45"
            fill="none"
          >
            <path
              d="M19.4155 34.3491C19.0864 34.3495 18.7682 34.2318 18.5187 34.0173C18.2692 33.8028 18.105 33.5059 18.0561 33.1805C17.9611 32.5546 17.8226 31.936 17.6416 31.3293C17.2608 29.8227 16.5644 28.4141 15.5985 27.1966C14.0399 25.3334 13.211 22.9676 13.266 20.539C13.273 19.272 13.5309 18.0189 14.025 16.8522C14.5191 15.6854 15.2395 14.6281 16.1446 13.7415C17.0497 12.8548 18.1215 12.1562 19.2981 11.6862C20.4748 11.2162 21.7329 10.984 22.9998 11.003C24.2667 10.984 25.5248 11.2162 26.7015 11.6862C27.8781 12.1562 28.95 12.8548 29.8551 13.7415C30.7601 14.6281 31.4805 15.6854 31.9746 16.8522C32.4687 18.0189 32.7267 19.272 32.7336 20.539C32.7886 22.9676 31.9597 25.3334 30.4011 27.1966C29.4353 28.414 28.7389 29.8227 28.3581 31.3293C28.1771 31.936 28.0385 32.5546 27.9435 33.1805C27.8946 33.5059 27.7304 33.8028 27.4809 34.0173C27.2314 34.2318 26.9132 34.3495 26.5842 34.3491L19.4155 34.3491Z"
              fill="#98ACF8"
            />
            <path
              d="M23.0229 11.003C22.7309 11.003 22.4453 11.0209 22.1619 11.0439C24.564 11.2204 26.8113 12.2958 28.4558 14.0557C30.1003 15.8155 31.0211 18.1305 31.0347 20.5391C31.0897 22.9676 30.2608 25.3334 28.7022 27.1967C27.7363 28.4141 27.0399 29.8227 26.6591 31.3294C26.4781 31.936 26.3396 32.5546 26.2446 33.1805C26.1957 33.5058 26.0316 33.8027 25.7822 34.0172C25.5328 34.2317 25.2146 34.3494 24.8857 34.3491H26.6072C26.9363 34.3495 27.2545 34.2318 27.504 34.0173C27.7535 33.8028 27.9177 33.5059 27.9666 33.1805C28.0616 32.5546 28.2001 31.936 28.3811 31.3294C28.7619 29.8227 29.4583 28.4141 30.4242 27.1967C31.9828 25.3334 32.8117 22.9676 32.7567 20.5391C32.7497 19.272 32.4918 18.0189 31.9977 16.8522C31.5036 15.6854 30.7832 14.6282 29.8781 13.7415C28.973 12.8548 27.9012 12.1562 26.7245 11.6862C25.5479 11.2162 24.2898 10.984 23.0229 11.003Z"
              fill="#778CBC"
            />
            <path
              d="M22.9771 11.003C23.2691 11.003 23.5547 11.0209 23.8381 11.0439C21.436 11.2204 19.1886 12.2958 17.5442 14.0557C15.8997 15.8155 14.9788 18.1305 14.9653 20.5391C14.9103 22.9676 15.7392 25.3334 17.2978 27.1967C18.2637 28.4141 18.9601 29.8227 19.3409 31.3294C19.5219 31.936 19.6604 32.5546 19.7554 33.1805C19.8043 33.5058 19.9684 33.8027 20.2178 34.0172C20.4672 34.2317 20.7854 34.3494 21.1143 34.3491H19.3928C19.0637 34.3495 18.7455 34.2318 18.496 34.0173C18.2465 33.8028 18.0823 33.5059 18.0334 33.1805C17.9384 32.5546 17.7999 31.936 17.6188 31.3294C17.2381 29.8227 16.5417 28.4141 15.5758 27.1967C14.0172 25.3334 13.1883 22.9676 13.2433 20.5391C13.2502 19.272 13.5082 18.0189 14.0023 16.8522C14.4964 15.6854 15.2168 14.6282 16.1219 13.7415C17.027 12.8548 18.0988 12.1562 19.2754 11.6862C20.4521 11.2162 21.7102 10.984 22.9771 11.003Z"
              fill="#B6CDFF"
            />
            <path
              d="M22.3323 6.95237V4.38821C22.3337 4.21205 22.4046 4.04359 22.5297 3.91952C22.6547 3.79545 22.8237 3.72583 22.9999 3.72583C23.176 3.72583 23.3451 3.79545 23.4701 3.91952C23.5952 4.04359 23.6661 4.21205 23.6675 4.38821V6.95237C23.6661 7.12852 23.5952 7.29698 23.4701 7.42105C23.3451 7.54512 23.176 7.61474 22.9999 7.61474C22.8237 7.61474 22.6547 7.54512 22.5297 7.42105C22.4046 7.29698 22.3337 7.12852 22.3323 6.95237Z"
              fill="#F05454"
            />
            <path
              d="M14.7019 9.8655L13.1947 7.79105C13.0923 7.64773 13.0507 7.46975 13.079 7.29589C13.1072 7.12204 13.203 6.96639 13.3455 6.86286C13.488 6.75933 13.6657 6.71631 13.8397 6.74316C14.0138 6.77001 14.1702 6.86457 14.2749 7.00623L15.7821 9.08068C15.8843 9.22401 15.9258 9.40192 15.8975 9.57569C15.8692 9.74945 15.7734 9.905 15.6309 10.0085C15.4885 10.112 15.311 10.155 15.137 10.1282C14.963 10.1014 14.8066 10.007 14.7019 9.8655Z"
              fill="#F05454"
            />
            <path
              d="M10.241 16.7074L7.80234 15.9151C7.63535 15.8592 7.49718 15.7397 7.41791 15.5824C7.33865 15.4252 7.3247 15.243 7.37911 15.0756C7.43352 14.9081 7.55188 14.7689 7.70842 14.6883C7.86496 14.6077 8.047 14.5922 8.21492 14.6452L10.6536 15.4376C10.8207 15.4933 10.9591 15.6128 11.0385 15.7701C11.1179 15.9274 11.1319 16.1097 11.0774 16.2772C11.023 16.4448 10.9045 16.5841 10.7478 16.6646C10.5911 16.7452 10.4089 16.7606 10.241 16.7074Z"
              fill="#F05454"
            />
            <path
              d="M10.6536 24.8647L8.21492 25.6571C8.047 25.7101 7.86496 25.6946 7.70842 25.6139C7.55188 25.5333 7.43352 25.3942 7.37911 25.2267C7.3247 25.0592 7.33865 24.8771 7.41791 24.7198C7.49718 24.5626 7.63535 24.4431 7.80234 24.3872L10.241 23.5949C10.4089 23.5419 10.5909 23.5575 10.7474 23.6381C10.9039 23.7187 11.0223 23.8579 11.0767 24.0253C11.1311 24.1927 11.1172 24.3749 11.0379 24.5321C10.9587 24.6893 10.8205 24.8089 10.6536 24.8647Z"
              fill="#F05454"
            />
            <path
              d="M35.7588 23.5945L38.1974 24.3869C38.3644 24.4427 38.5025 24.5623 38.5818 24.7195C38.6611 24.8767 38.675 25.0589 38.6206 25.2264C38.5662 25.3938 38.4478 25.533 38.2913 25.6136C38.1348 25.6942 37.9527 25.7097 37.7848 25.6568L35.3461 24.8644C35.1791 24.8086 35.0408 24.6891 34.9615 24.5318C34.8821 24.3746 34.8681 24.1923 34.9226 24.0248C34.977 23.8573 35.0954 23.7181 35.2521 23.6375C35.4087 23.5569 35.5908 23.5414 35.7588 23.5945Z"
              fill="#F05454"
            />
            <path
              d="M35.3462 15.4376L37.7848 14.6452C37.9527 14.5922 38.1348 14.6077 38.2913 14.6883C38.4479 14.7689 38.5662 14.9081 38.6206 15.0756C38.675 15.243 38.6611 15.4252 38.5818 15.5824C38.5026 15.7397 38.3644 15.8592 38.1974 15.9151L35.7587 16.7074C35.5908 16.7604 35.4088 16.7448 35.2523 16.6642C35.0958 16.5836 34.9775 16.4444 34.9231 16.277C34.8687 16.1095 34.8826 15.9274 34.9618 15.7702C35.0411 15.613 35.1792 15.4934 35.3462 15.4376Z"
              fill="#F05454"
            />
            <path
              d="M30.2178 9.08068L31.725 7.00623C31.8297 6.86457 31.9861 6.77001 32.1602 6.74316C32.3342 6.71631 32.5119 6.75933 32.6544 6.86286C32.7969 6.96639 32.8927 7.12204 32.9209 7.29589C32.9492 7.46975 32.9076 7.64773 32.8052 7.79105L31.298 9.86547C31.1931 10.0064 31.0368 10.1002 30.8631 10.1266C30.6894 10.1531 30.5123 10.11 30.3702 10.0068C30.2281 9.90349 30.1323 9.74836 30.1038 9.575C30.0753 9.40165 30.1163 9.22404 30.2178 9.08068Z"
              fill="#F05454"
            />
            <path
              d="M26.4256 41.2738H19.5377C19.355 41.2738 19.1798 41.2013 19.0506 41.0721C18.9214 40.9429 18.8489 40.7677 18.8489 40.585C18.8489 40.4024 18.9214 40.2272 19.0506 40.098C19.1798 39.9688 19.355 39.8962 19.5377 39.8962H26.4256C26.6083 39.8962 26.7835 39.9688 26.9127 40.098C27.0418 40.2272 27.1144 40.4024 27.1144 40.585C27.1144 40.7677 27.0418 40.9429 26.9127 41.0721C26.7835 41.2013 26.6083 41.2738 26.4256 41.2738Z"
              fill="#F05454"
            />
            <path
              d="M27.1145 37.141H18.849C18.6663 37.141 18.4911 37.0684 18.3619 36.9393C18.2327 36.8101 18.1602 36.6349 18.1602 36.4522C18.1602 36.2695 18.2327 36.0943 18.3619 35.9652C18.4911 35.836 18.6663 35.7634 18.849 35.7634H27.1145C27.2972 35.7634 27.4724 35.836 27.6015 35.9652C27.7307 36.0943 27.8033 36.2695 27.8033 36.4522C27.8033 36.6349 27.7307 36.8101 27.6015 36.9393C27.4724 37.0684 27.2972 37.141 27.1145 37.141Z"
              fill="#F05454"
            />
            <path
              d="M27.1145 39.2074H18.849C18.6663 39.2074 18.4911 39.1349 18.3619 39.0057C18.2327 38.8765 18.1602 38.7013 18.1602 38.5186C18.1602 38.3359 18.2327 38.1608 18.3619 38.0316C18.4911 37.9024 18.6663 37.8298 18.849 37.8298H27.1145C27.2972 37.8298 27.4724 37.9024 27.6015 38.0316C27.7307 38.1608 27.8033 38.3359 27.8033 38.5186C27.8033 38.7013 27.7307 38.8765 27.6015 39.0057C27.4724 39.1349 27.2972 39.2074 27.1145 39.2074Z"
              fill="#F05454"
            />
            <path
              d="M24.3836 26.2759C24.1677 26.4031 23.9333 26.4957 23.6888 26.5505V27.3216C23.6888 27.5043 23.6162 27.6795 23.487 27.8086C23.3579 27.9378 23.1827 28.0104 23 28.0104C22.8173 28.0104 22.6421 27.9378 22.5129 27.8086C22.3838 27.6795 22.3112 27.5043 22.3112 27.3216V26.5734C21.7235 26.4744 21.1546 26.2857 20.6242 26.0139C20.4624 25.931 20.34 25.7875 20.2836 25.6147C20.2271 25.442 20.2413 25.2539 20.3229 25.0914C20.4046 24.929 20.5471 24.8055 20.7194 24.7477C20.8918 24.6899 21.08 24.7026 21.243 24.783C22.3522 25.341 23.263 25.343 23.6733 25.0954C23.9327 24.9336 24.1352 24.6949 24.2526 24.4126C24.37 24.1303 24.3965 23.8185 24.3285 23.5204C24.1543 23.0025 23.9706 22.8653 22.365 22.0544C21.3991 21.5667 20.3202 20.7962 20.5489 19.1549C20.6075 18.6805 20.8066 18.2344 21.1206 17.874C21.4347 17.5136 21.8493 17.2553 22.3112 17.1323V16.3009C22.3112 16.1182 22.3838 15.943 22.5129 15.8138C22.6421 15.6846 22.8173 15.6121 23 15.6121C23.1827 15.6121 23.3579 15.6846 23.487 15.8138C23.6162 15.943 23.6888 16.1182 23.6888 16.3009V17.0038C24.2576 17.0345 24.812 17.1936 25.3105 17.4693C25.4679 17.5601 25.5829 17.7093 25.6307 17.8845C25.6786 18.0598 25.6552 18.2468 25.5658 18.4049C25.4764 18.563 25.3282 18.6794 25.1534 18.7288C24.9787 18.7782 24.7914 18.7566 24.6325 18.6686C24.1839 18.4265 23.6736 18.323 23.1661 18.3709C22.8753 18.3564 22.5888 18.4467 22.3589 18.6255C22.1289 18.8042 21.9707 19.0595 21.913 19.3449C21.8437 19.8414 21.8794 20.2655 22.9865 20.8248C24.5491 21.6138 25.2621 21.974 25.6348 23.0815C25.8044 23.6775 25.7739 24.3127 25.548 24.8897C25.322 25.4667 24.9129 25.9537 24.3836 26.2759Z"
              fill="#F6F4E6"
            />
          </svg>
        </div>
      ),
      title: "For Professionals and Innovators",
      text: "With features like MEMS Microphone and RGB LEDs, Sensy32 is ideal for developing advanced prototypes in fields such as wearable technology, automation, and environmental monitoring.       ",
    },
  ];

  return (
    <section className={`${styles.paddingX} mt-[100px]`}>
      <h2 className={`${typo.h3} mb-[35px]`}>Explore The Sensor Spectrum</h2>
      <div className="flex flex-wrap gap-[50px] justify-center items-center sm:items-stretch">
        {spectrum.map((card, index) => {
          return (
            <div
              key={`card number ${index}`}
              className="spectrum-card flex flex-1 flex-col items-center gap-[15px] w-full min-w-[300px]"
            >
              {card.icon}

              <h3 className="text-[#2D2D2D] text-[28px] font-Inter font-[600] text-center">
                {card.title}
              </h3>
              <p className="text-[#2D2D2D] text-[16px] font-Inter font-[400] text-center">
                {card.text}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Section5;
