import axios from "axios";

const addWidgetToCustomDashboard = async (widgetId, userId) => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error("Token is missing");
            return;
        }
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ` + token

        };

        const url = `${process.env.REACT_APP_SERVER_URL}/custom-dashboard/add-widget`;
        const response = await axios.post(url, { widgetId, userId }, { headers });
        return response.data;
    } catch (error) {
        console.error("Error adding widget to custom dashboard:", error);
        throw error;
    }
};
const getWidgetsFromCustomDashboard = async (userId) => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error("Token is missing");
            return;
        }
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ` + token

        };
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/custom-dashboard/widgets/${userId}`, { headers });
        return response.data;
    } catch (error) {
        console.error('Error fetching widgets from custom dashboard:', error);
        throw error;
    }
};


const getCustomDashByUser = async (userId) => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error("Token is missing");
            return;
        }
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ` + token

        };
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/custom-dashboard/by-user/${userId}`, { headers });
        return response.data;
    } catch (error) {
        console.error("Error fetching customized dashboard:", error);
        throw error;
    }
};

const getAllCustomDashboards = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error("Token is missing");
            return;
        }
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ` + token

        };
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/custom-dashboard`,{headers});
        return response.data.customizedDashboards;
    } catch (err) {
        console.error("Error fetching all customized dashboards:", err);
        throw err;
    }
};

const updateCustomDashboardByUser = async (userId, updates) => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error("Token is missing");
            return;
        }
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ` + token

        };
        await axios.put(`${process.env.REACT_APP_SERVER_URL}/custom-dashboard/by-user/${userId}`, updates,{headers});
    } catch (err) {
        console.error("Error updating customized dashboard:", err);
        throw err;
    }
};

export {
    addWidgetToCustomDashboard, getWidgetsFromCustomDashboard, getCustomDashByUser, getAllCustomDashboards, updateCustomDashboardByUser
};
