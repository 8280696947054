import axios from "axios";

const fetchSensors = async (userId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ` + token

    };
    //get user's sensors list
    const url = `${process.env.REACT_APP_SERVER_URL}/sensors/by-user/${userId}`;
    const response = await axios.get(url,{headers});
    return response.data.sensors;
  } catch (error) {
    console.error("Error fetching sensors:", error);
    throw error;
  }
};

export { fetchSensors };
