import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { Modal } from "@mui/material";

// widgets
import pie_icon from "../../assets/images/pie-icon.svg";
import bar_icon from "../../assets/images/bar-icon.svg";
import line_icon from "../../assets/images/line-icon.svg";
import switch_icon from "../../assets/images/switch-icon.svg";
import gauge_icon from "../../assets/images/gauge-icon.svg";
import colors_icon from "../../assets/images/colors-icon.svg";
import text_icon from "../../assets/images/text-icon.svg";
import img_icon from "../../assets/images/img-icon.svg";
import count_icon from "../../assets/images/count-icon.svg";
import schedule_icon from "../../assets/images/schedule-icon.svg";
import table_icon from "../../assets/images/table-icon.svg";
import slider_icon from "../../assets/images/slider-icon.svg";
import map_icon from "../../assets/images/map-icon.svg";
import temp_icon from "../../assets/images/temp-icon.svg";
import { ThemeContext } from "../../components/ThemeContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUser } from "../../state/UserContext";
const Dashboard = () => {
  const UserThemColor = ThemeContext;
  const [selectedColor, setSelectedColor] = useState("#0000ff");

  const handleColorChange = (e) => {
    setSelectedColor(e.target.value);
  };
  const [addedComponents, setAddedComponents] = useState([]);

  const [open, setOpen] = useState(false);
  const [openWidgets, setOpenWidgets] = useState(false);

  const handleOpen = () => {
    window.location.href = "/dashboards";
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const widgets = [
    {
      img: pie_icon,
      text: "Pie chart",
    },
    {
      img: bar_icon,
      text: "Bar chart",
    },
    {
      img: line_icon,
      text: "Line chart",
    },
    {
      img: switch_icon,
      text: "Switch",
    },
    {
      img: gauge_icon,
      text: "Gauge",
    },
    {
      img: pie_icon,
      text: "Percentage",
    },
    {
      img: colors_icon,
      text: "Colors",
    },
    {
      img: text_icon,
      text: "Text",
    },
    {
      img: img_icon,
      text: "Image",
    },
    {
      img: count_icon,
      text: "Count down",
    },
    {
      img: schedule_icon,
      text: "Schedule",
    },
    {
      img: table_icon,
      text: "Table",
    },
    {
      img: slider_icon,
      text: "Slider",
    },
    {
      img: map_icon,
      text: "Map",
    },
    {
      img: temp_icon,
      text: "Temperature",
    },
  ];

  const [searchText, setSearchText] = useState("");
  const [filteredWidgets, setFilteredWidgets] = useState(widgets);
  const { userData, setUserData } = useUser();
  const [searchParams] = useSearchParams();
  const { setUser } = useUser();
  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchText(searchTerm);

    const filtered = widgets.filter((widget) =>
      widget.text.toLowerCase().includes(searchTerm)
    );

    setFilteredWidgets(filtered);
  };
  useEffect(() => {
    const myParam = searchParams.get("data");
    if (myParam != null) {
      const { user, token } = JSON.parse(myParam);
      setUser(JSON.parse(myParam));
      setUserData(user);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      window.location.href = "/realtime-data";
    }
  }, []);
  return (
    <div>
      <div className="flex flex-col justify-center items-center h-[calc(100vh-95px)] overflow-y-scroll ">
        <h3 className="text-center text-text_black max-w-[350px] xs:max-w-[600px] text-[28px] font-[700] leading-[44px]">
          Hello{` ${userData.firstName}`}, Create New Dashboard{" "}
        </h3>
        <p className="text-center xs:max-w-[450px] max-w-[400px] text-[15px] text-[#787878] mt-[12px]">
          Start customizing your dashboard by adding or rearranging widgets.
          Your sensor data, your way.
        </p>

        <button
          className="hidden md:flex items-center gap-2 mt-5 bg-dark_green text-white rounded-[10px] sm:text-[16px] text-[15px] font-[500] px-[30px] py-[2px] sm:h-[45px] h-[45px] transition-all duration-75 active:scale-90"
          type="button"
          onClick={handleOpen}
          style={{
            backgroundColor: localStorage.getItem("theme-color") || "#168004",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 29 29"
            fill="none"
          >
            <path
              d="M25.0919 16.7236H16.7346V25.0809H25.0919V16.7236Z"
              stroke="white"
              strokeWidth="2.38778"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.9591 16.7236H3.60187V25.0809H11.9591V16.7236Z"
              stroke="white"
              strokeWidth="2.38778"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25.0919 3.59082H16.7346V11.948H25.0919V3.59082Z"
              stroke="white"
              strokeWidth="2.38778"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.9591 3.59082H3.60187V11.948H11.9591V3.59082Z"
              stroke="white"
              strokeWidth="2.38778"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Create Custom Dashboard</span>
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
