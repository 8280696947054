import axios from "axios";

const fetchLastNotifications = async (userId) => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error("Token is missing");
          return;
        }
        const headers = {
          "Content-Type": "application/json",
          "Authorization": `Bearer ` + token
    
        };
        // Get user's last notifications
        const url = `${process.env.REACT_APP_SERVER_URL}/notifications/last-notifications/${userId}`;
        const response = await axios.get(url,{headers});
        return response.data;
    } catch (error) {
        console.error("Error fetching last notifications:", error);
        throw error;
    }
};

const clearNotifications = async (userId) => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error("Token is missing");
          return;
        }
        const headers = {
          "Content-Type": "application/json",
          "Authorization": `Bearer ` + token
    
        };
        const url = `${process.env.REACT_APP_SERVER_URL}/notifications/clear-by-user/${userId}`;
        const response = await axios.delete(url,{headers});
        return response.status;
    } catch (error) {
        console.error("Error when trying to clear notifications for the user:", error);
        throw error;
    }
}

export { fetchLastNotifications, clearNotifications };