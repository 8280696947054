import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { useUser } from "../../state/UserContext";
import home_icon from '../../assets/images/home-icon.svg';
import { ThemeContext } from "../../state/ThemeContext";
import crudIcon from '../../assets/images/crud-icon.svg';
import delete_icon from '../../assets/images/delete-icon.svg';
import CustomSnackbar from "../CustomSnackbar";
import { addWidgetToCustomDashboard } from "../../services/customDashboardService";
import DotsDark from '../../assets/images/dark-dots.svg';

const OrientationWidget = ({ value, color, sensor, type, chart, widget, onDelete, updatedAtArray, boardName, width, fromHome }) => {
  const [themeColor, setThemeColor] = useState("");
  const ITEM_HEIGHT = 78;

  const { userData } = useUser();
  const theme = useContext(ThemeContext).theme;

  useEffect(() => {
    const currentColor = localStorage.getItem("theme-color");
    setThemeColor(currentColor);
  }, []);

  const [series, setSeries] = useState([{
    name: "quatI", data: []
  }, {
    name: "quatJ", data: []
  }, {
    name: "quatK", data: []
  }, {
    name: "quatReal", data: []
  }, {
    name: "quatRadianAccuracy", data: []
  }]);
  const [dropdownStates, setDropdownStates] = useState(false);
  const toggleDropdown = () => {
    setDropdownStates(!dropdownStates);
  };

  useEffect(() => {
    const iData = [];
    const jData = [];
    const kData = [];
    const realData = [];
    const accData = [];
    value.forEach(item => {
      iData.push(parseFloat(item.quatI));
      jData.push(parseFloat(item.quatJ));
      kData.push(parseFloat(item.quatK));
      realData.push(parseFloat(item.quatReal));
      accData.push(parseFloat(item.quatRadianAccuracy));
    });
    setSeries([{
      name: "quatI",
      data: iData,
    },
    {
      name: "quatJ",
      data: jData,
    },
    {
      name: "quatK",
      data: kData,
    },
    {
      name: "quatReal",
      data: realData,
    }, {
      name: "quatRadianAccuracy",
      data: accData,
    }]);
  }, [value])

  const options = {
    chart: {
      height: 350,
      type: type,
      zoom: {
        enabled: false,
      },
    },
    toolbar: {
      show: false,
    },
    foreColor: theme === "dark" ? "#ffffff" : "#000000",
    zoom: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    legend: {
      position: "top",
    },
    xaxis: {
      categories: updatedAtArray.map(date => new Date(date).toLocaleTimeString()),
      title: { text: ` ${new Date(updatedAtArray[0]).toDateString()}` },
      tickAmount: 3
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return parseInt(value).toString();
        },
      },
    },
  };
  if (type === 'line') {
    options.stroke = {
      width: 3,
      curve: 'smooth',
    };
  } else if (type === 'bar') {
    options.plotOptions = {
      bar: {
        borderRadius: 10,
        barWidth: 50,
        columnWidth: '70%'
      },
    };
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();



  const handleEditClick = () => {
    // handleClose();

  };
  const handleDeleteClick = async (id) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token

      };
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}/widgets/${widget}`, { headers });
      onDelete(widget);
    } catch (error) {
      console.error('Error deleting widget:', error);
    }
  };
  const [successMessage, setSuccessMessage] = useState(false);

  const handleAddWidgetToDashboard = async () => {
    try {
      const userId = userData.id;
      const widgetId = widget;
      await addWidgetToCustomDashboard(widgetId, userId);
      setSuccessMessage(true);
    } catch (error) {
      console.error("Error adding widget to custom dashboard:", error);
      throw error;
    }
  };
  return (
    <div
      className={`spline-area-chart rounded-[12px] shadow-md  bg-white dark:bg-dark2 p-[15px]`}
    >

      <div className="flex flex-row justify-between items-center px-[20px] p-7">
        {fromHome ? (
          <div className="flex flex-col lg:flex-row lg:items-center w-full">
            <div className="flex flex-col lg:flex-row lg:items-center mb-4 lg:mb-0 lg:mr-20">
              <div className={`text-lg font-medium ${theme === "dark" ? "text-white" : "text-black"}`}>Board:</div>
              <p className={`text-base ${theme === "dark" ? "text-white" : "text-black"} lg:ml-2`}>{boardName}</p>
            </div>
            <div className="flex flex-col lg:flex-row  lg:justify-end xl:ml-[12rem] lg:mx-auto">
              <div className={`text-lg font-medium ${theme === "dark" ? "text-white" : "text-black"}`}>Sensor:</div>
              <p className={`text-base ${theme === "dark" ? "text-white" : "text-black"} lg:ml-2`}> Orientation </p>
            </div>
          </div>
        ) : (
          <div className={`text-base ${theme === "dark" ? "text-white" : "text-black"}`}>
            Orientation
          </div>
        )}

        <div >
          {!fromHome &&
            <div className="relative">
              <img src={theme === "dark" ? DotsDark : crudIcon} alt='edit-image' className='w-9 p-2 cursor-pointer' onClick={() => toggleDropdown()} />

              {dropdownStates && (

                <div className={`absolute top-10 right-0 shadow-md w-60 rounded-lg z-50 p-2 ${theme === "dark" ? "text-white bg-[#262626]" : "bg-white"}`}>

                  <div className='cursor-pointer p-2 flex' onClick={handleDeleteClick}>
                    <div className='rounded-[8px] bg-[#D2D2D240] p-2'>
                      <img src={delete_icon} alt="delete-icon" />
                    </div>
                    <span className='ml-4 mt-1'>Delete</span>
                  </div>

                  <div className='cursor-pointer p-2 flex' onClick={handleAddWidgetToDashboard} >
                    <div className='rounded-[8px] bg-[#D2D2D240] p-2'>
                      <img src={home_icon} alt="home-icon" />
                    </div>
                    <span className='ml-4 mt-1'>Add To Home Page </span>
                  </div>
                </div>
              )}
            </div>}
          {/* <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                    >
                        <MoreVertIcon />
                    </IconButton> */}
          {/* <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "120px",
                marginRight: "30px",
              },
            }}
          >
            <MenuItem onClick={handleEditClick}>
              <span
                style={{
                  backgroundColor: "rgba(210, 210, 210, 0.25)",
                  borderRadius: "4px",
                  padding: "6px",
                  marginRight: "10px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1205_20263)">
                    <path
                      d="M6.36719 10.5491H11.0822"
                      stroke="black"
                      strokeWidth="1.17875"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.72483 1.90485C8.93325 1.69643 9.21592 1.57935 9.51067 1.57935C9.65661 1.57935 9.80112 1.60809 9.93596 1.66394C10.0708 1.71979 10.1933 1.80165 10.2965 1.90485C10.3997 2.00804 10.4816 2.13056 10.5374 2.26539C10.5933 2.40022 10.622 2.54474 10.622 2.69068C10.622 2.83662 10.5933 2.98114 10.5374 3.11597C10.4816 3.2508 10.3997 3.37332 10.2965 3.47651L3.7479 10.0251L1.65234 10.549L2.17623 8.45345L8.72483 1.90485Z"
                      stroke="black"
                      strokeWidth="1.17875"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1205_20263">
                      <rect
                        width="12.5733"
                        height="12.5733"
                        fill="white"
                        transform="translate(0.0805664 0.0712891)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <span className="text-[12px]">Edit</span>
            </MenuItem>
            <MenuItem onClick={handleDeleteClick}>
              <span
                style={{
                  backgroundColor: "rgba(210, 210, 210, 0.25)",
                  borderRadius: "4px",
                  padding: "4px",
                  marginRight: "10px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M4.6093 12.9918C4.6093 13.7012 5.18974 14.2817 5.89918 14.2817H11.0587C11.7681 14.2817 12.3486 13.7012 12.3486 12.9918V6.54238C12.3486 5.83294 11.7681 5.25249 11.0587 5.25249H5.89918C5.18974 5.25249 4.6093 5.83294 4.6093 6.54238V12.9918ZM12.3486 3.31767H10.7362L10.2783 2.85976C10.1622 2.74367 9.99456 2.67273 9.82687 2.67273H7.13102C6.96333 2.67273 6.79565 2.74367 6.67956 2.85976L6.22165 3.31767H4.6093C4.25458 3.31767 3.96436 3.60789 3.96436 3.96261C3.96436 4.31733 4.25458 4.60755 4.6093 4.60755H12.3486C12.7033 4.60755 12.9935 4.31733 12.9935 3.96261C12.9935 3.60789 12.7033 3.31767 12.3486 3.31767Z"
                    fill="black"
                  />
                </svg>
              </span>
              <span className="text-[12px]">Delete</span>
            </MenuItem>
          </Menu>  */}
        </div>


      </div>
      <ReactApexChart options={options} series={series} type={type} height={350}
        width={width} />
      <CustomSnackbar
        openSnackbar={successMessage}
        closeSnackbar={() => {
          setSuccessMessage(false);
        }}
        message="Widget added to your home page successfully!"
      />
    </div>
  );
};

export default OrientationWidget;