import React, { useContext, useEffect, useState } from 'react';
import filter_icon from "../assets/images/filter.svg";
import filter_icon_dark from "../assets/images/filter-dark.svg";

import { ThemeContext } from '../state/ThemeContext';
let userDashboard;
const Filter = ({ onClick, backgroundColor, dashboards }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {

    }, [])
    const theme = useContext(ThemeContext).theme;
    userDashboard = dashboards;
    return (

        <div>
            <button
                className={`flex md:flex mt-8 md:mt-0 items-center justify-center gap-1  border rounded-[10px] sm:text-[16px] text-[15px] font-[500] px-[30px] py-[2px] sm:h-[45px] h-[45px] transition-all duration-75 active:scale-90 flex-grow ${theme === "dark" ? "bg-[#252729]  border-white" : "bg-[#F4F5FB]  border-[#168004]"}`}
                type="button"
                onClick={toggleDropdown}
            >
                <span className={`text-[18px]  font-[500] ${theme === "dark" ? "text-white" : "text-[#176A0A]"}`}>Filter</span>

                <img src={theme === "dark" ? filter_icon_dark : filter_icon} alt="filter icon" className='ml-2' />
            </button>
            {isOpen && (
                <div className={`absolute top-30 right-3  md:right-6  p-2 w-[300px] lg:w-[340px] mt-1  shadow-md rounded-lg z-50 ${theme === "dark" ? "bg-[#252729]" : "bg-white"}`}>
                    <div className='p-2 rounded-[6px]'>
                        <div className={`text-[16px] font-[500] ${theme === "dark" ? "text-white" : "text-[#262626]"}`}>Dashboard</div>
                        <select
                            className={`rounded-[8px] mt-2 mb-[10px] border border-[#E5E5E5]  focus:outline-none h-[40px] ${theme === "dark"
                                    ? "bg-[#4A4A4A] text-white border-none"
                                    : "bg-white"
                                }`}
                        >
                            {dashboards.map((dashboard) => (
                                <option key={dashboard.id} value={dashboard.id}>
                                    {dashboard.name}
                                </option>
                            ))}
                        </select>
                        <div className='flex justify-end items-end '>
                            <button className='bg-[#16590E] rounded-[8px] p-2 text-white min-w-[90px]'>
                                Filter
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Filter;
