import axios from "axios";


const fetchDashboards = async (userId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ` + token

    };
    // Get user's boards list
    const url = `${process.env.REACT_APP_SERVER_URL}/dashboards/by-user/${userId}`;
    const response = await axios.get(url, { headers });
    return response.data.dashboards;
  } catch (error) {
    console.error("Error fetching dashboards:", error);
    throw error;
  }
};
const addUserForDashboard = async (email, dashboardId) => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    return;
  }
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ` + token

  };
  const url = `${process.env.REACT_APP_SERVER_URL}/dashboards/add-member/`;
  const response = await axios.post(url, { email, dashboardId }, { headers });
  if (response.data.message == "User not found") {
    return 'user-not-found';
  } else if (response.status == 200) {
    return 'added-successfully';
  }
  else if (response.status == 404) {
    return 'dashboard-not-found';
  } else if (response.status == 400) {
    return 'user-already-exists';
  } else {
    return 'unexpected-error';
  }
};
const getUsersForDashboard = async (dashboardId) => {

  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    return;
  }
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ` + token

  };
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/dashboards/get-members/${dashboardId}`, { headers });
  if (response.status === 400) {
    return response.data.message;
  } else {
    return response.data.dashboardUsers
  }
};
const getUserByEmail = async (email) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ` + token

    };
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/user-by-email/${email}`, { headers });
    return response.data.user;
  } catch (error) {
    console.error("Error fetching user by email:", error);
    throw error;
  }
};
const removeUserFromDashboard = async (email, dashboardId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ` + token

    };
    const url = `${process.env.REACT_APP_SERVER_URL}/dashboards/remove-member`;
    const response = await axios.post(url, { email, dashboardId }, { headers });
    return response.data;
  } catch (error) {
    console.error("Error removing user from dashboard:", error);
    throw error;
  }
};
const updateDashboard = async (dashboardId, data) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ` + token

    };
    const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/dashboards/${dashboardId}`, data, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export {
  fetchDashboards, addUserForDashboard, getUsersForDashboard, getUserByEmail,
  removeUserFromDashboard, updateDashboard
};

