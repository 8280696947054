import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import plus from "../../assets/images/mobile-icon-add.svg";
import { Checkbox, Pagination, CircularProgress } from "@mui/material";
import { useUser } from "../../state/UserContext";
import axios from "axios";
import AddBoardModal from "../../components/modals/boards/AddBoardModal";
import UpdateBoardModal from "../../components/modals/boards/UpdateBoardModal";
import DeleteBoardModal from "../../components/modals/boards/DeleteBoardModal";
import { ThemeContext } from "../../state/ThemeContext";
import { fetchBoards } from "../../services/boardService";
import GeneralButton from "../../components/Button";
import CustomSnackbar from "../../components/CustomSnackbar";

const columns = [
  { id: "logo", label: "Logo" },
  { id: "name", label: "Name" },
  // { id: "key", label: "API Key" },
  { id: "is_realtime", label: "Status" },
  { id: "action", label: "Action" },
];
const BoardsPage = () => {
  const [showSuccessDelete, setShowSuccessDelete] = useState(false);
  const { userData } = useUser();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [selected, setSelected] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [boards, setBoards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // Modal Add Board
  const [open, setOpen] = useState(false);
  // Modal Update Board
  const [openUpdate, setOpenUpdate] = useState(false);
  const [selectedBoard, setSelectedBoard] = useState("");
  //  Delete Board
  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = (board) => {
    setSelectedBoard(board);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);
  const [formData, setFormData] = useState({
    name: "",
    ip: "",
    key: "",
    logo: "",
    role: "",
    isRealtime: false,
  });
  const { theme } = useContext(ThemeContext);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px", // Default width for mobile screens
    borderRadius: "16px",
    color: theme === "dark" ? "white" : "black",
    backgroundColor: theme === "dark" ? "#1E1E1E" : "#FFFFFF",
    boxShadow: 24,
    paddingTop: 3,
    paddingLeft: "30px",
    paddingRight: "30px",
  };
  if (window.matchMedia("(max-width: 600px)").matches) {
    style.width = "300px";
  }
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const boardsData = await fetchBoards(userData.id);
      setBoards(boardsData);
    } catch (error) {
      console.error("Error fetching boards:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const LoadingIndicator = () => (
    <TableRow>
      <TableCell colSpan="50%" align="center">
        <CircularProgress
          style={{
            color: localStorage.getItem("theme-color") || userData.themeColor,
          }}
        />
      </TableCell>
    </TableRow>
  );

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = boards.map((row) => row.id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };
  const handleUpdateFormData = (updatedData) => {
    setFormData(updatedData);
  };
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
  };
  const isItemSelected = (id) => selected.indexOf(id) !== -1;
  const isAllSelected = selected.length === boards.length;
  const numSelected = selected.length;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = async (newBoard) => {
    setOpen(false);
    // await fetchBoards();
    fetchData();
  };

  const handleUpdate = async (id) => {
    setOpenUpdate(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token

      };
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/boards/${id}`,
        { headers }
      );
      setFormData(response.data);
    } catch (error) {
      console.error("Error fetching board:", error);
    }
    setSelectedBoard(id);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    fetchData();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDeleteBoard = async (id) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token

      };
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/boards/${id}`, { headers }
      );
      fetchData();

      if (response.status == 200) {
        handleCloseDelete();
        setShowSuccessDelete(true);
        const userNbBoards = parseInt(localStorage.getItem("nb_boards")) || 0;
        const updateNbBoards = userNbBoards - 1;
        localStorage.setItem("nb_boards", updateNbBoards);
      }
    } catch (error) {
      console.error("Error deleting board:", error);
    }
  };

  //Search Board By Name
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    if (value === "") {
      fetchData();
    } else {
      const filteredBoards = boards.filter((board) =>
        board.name.toLowerCase().includes(value)
      );
      setBoards(filteredBoards);
    }
  };

  return (
    <div className=" justify-center mx-[32px] mt-[36px]">
      <div className={`flex flex-col md:flex-row justify-between items-start my-[10px] `}>
        <h2 className={`text-[25px] font-[600] ${theme === "dark" ? "text-white" : "text-black"}`}>
          Boards
        </h2>
        <div className="flex flex-col md:flex-row gap-2 mt-2 w-full md:justify-end md:items-end ">
          <div className={`rounded-[10px] h-[45px] flex border sm:flex md:max-w-[300px] ${theme === "dark" ? "bg-[#5E5E5E] border-[#5E5E5E]" : "bg-white border-[#E5E5E5]"}`}>
            <input
              type="text"
              placeholder="Search"
              className={` pl-[32px] text-[16px]  pr-4  rounded-[10px] focus:outline-none w-full
                ${theme === "dark" ? "bg-[#5E5E5E]" : "bg-white"}`}
              onChange={handleSearch}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="29"
              viewBox="0 0 24 25"
              fill="none"
              className={`mr-5 mt-2  bg-[#5E5E5E]
                ${theme === "dark" ? "bg-[#5E5E5E]" : "bg-white"}
                `}
            >
              <path
                d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z"
                stroke={localStorage.getItem("theme-color") || "#168004"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21 21.5L16.65 17.15"
                stroke={localStorage.getItem("theme-color") || "#168004"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className='flex md:items-end md:justify-end'>
            <GeneralButton
              onClick={handleOpen}
              backgroundColor={
                localStorage.getItem("theme-color") || userData.themeColor
              }
              className="mt-8 md:mt-0">
              Create
            </GeneralButton>
          </div>

          {/* <button
              className="flex items-center gap-1 bg-dark_green p-3 text-white rounded-[32px] transition-all duration-75 active:scale-90 md:hidden"
              type="button"
              onClick={handleOpen}
              style={{
                backgroundColor:
                  localStorage.getItem("theme-color") || userData.themeColor,
              }}
            >
              <img src={plus} alt="plus icon" className="w-6" />
            </button> */}
        </div>
      </div>
      <Paper
        className={`dark`}
        sx={{
          width: "100%",
          marginTop: "8px",
          // filter:
          //   "drop-shadow(0px 11.404958724975586px 68.42975616455078px rgba(226, 236, 249, 0.50))",
          border: "none",
          borderRadius: "20px",
        }}
      >

        <TableContainer sx={{ maxHeight: 863, border: "none" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  {/* <Checkbox
                    indeterminate={numSelected > 0 && !isAllSelected}
                    checked={isAllSelected}
                    onChange={handleSelectAllClick}
                  /> */}
                </TableCell>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <LoadingIndicator />
              ) : boards.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} align="center">
                    <div
                      className="text-[20px] font-semibold flex justify-center"
                      style={{
                        color:
                          localStorage.getItem("theme-color") ||
                          userData.themeColor,
                      }}
                    >
                      No boards found.
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                Array.isArray(boards) &&
                boards
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      key={row.id}
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected(row.id)}
                      selected={isItemSelected(row.id)}
                    >
                      <TableCell padding="checkbox">
                        {/* <Checkbox checked={isItemSelected(row.id)} /> */}
                      </TableCell>
                      {columns.map((column, index) =>
                        column.id === "is_realtime" ? (
                          <TableCell key={index}>
                            <div className="flex">
                              <div
                                className={`rounded-[19px] text-[16px] font-[500] px-[14px] py-[5px] min-w-[100px] flex justify-center ${row[column.id] === true
                                  ? "bg-green_label text-dark_green"
                                  : "bg-[#FFC5C5] text-[#C30D0D]"
                                  }`}
                              >
                                {row[column.id] === true
                                  ? "Active"
                                  : "Inactive"}
                              </div>
                            </div>
                          </TableCell>
                        ) : column.id === "action" ? (
                          <TableCell key={column.id}>
                            <div className="flex gap-[8px]">
                              <button
                                className="bg-icon_bg p-[6px] rounded-[4px] w-[26px] h-[26px] flex justify-center items-center"
                                type="button"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleUpdate(row.id);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <path
                                    d="M7 11.3333H11.875"
                                    stroke={
                                      theme === "dark" ? "#FFFFFF" : "#525256"
                                    }
                                    strokeWidth="1.21875"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M9.4375 2.39587C9.65299 2.18039 9.94525 2.05933 10.25 2.05933C10.4009 2.05933 10.5503 2.08905 10.6897 2.14679C10.8291 2.20454 10.9558 2.28918 11.0625 2.39587C11.1692 2.50257 11.2538 2.62924 11.3116 2.76865C11.3693 2.90806 11.399 3.05748 11.399 3.20837C11.399 3.35927 11.3693 3.50869 11.3116 3.6481C11.2538 3.78751 11.1692 3.91418 11.0625 4.02087L4.29167 10.7917L2.125 11.3334L2.66667 9.16671L9.4375 2.39587Z"
                                    stroke={
                                      theme === "dark" ? "#FFFFFF" : "#525256"
                                    }
                                    strokeWidth="1.21875"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                              <button
                                className="bg-icon_bg p-[6px] rounded-[4px] w-[26px] h-[26px] flex justify-center items-center"
                                onClick={() => {
                                  handleOpenDelete(row.id);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M4.50049 14.25C4.50049 15.075 5.17549 15.75 6.00049 15.75H12.0005C12.8255 15.75 13.5005 15.075 13.5005 14.25V6.75C13.5005 5.925 12.8255 5.25 12.0005 5.25H6.00049C5.17549 5.25 4.50049 5.925 4.50049 6.75V14.25ZM13.5005 3H11.6255L11.093 2.4675C10.958 2.3325 10.763 2.25 10.568 2.25H7.43299C7.23799 2.25 7.04299 2.3325 6.90799 2.4675L6.37549 3H4.50049C4.08799 3 3.75049 3.3375 3.75049 3.75C3.75049 4.1625 4.08799 4.5 4.50049 4.5H13.5005C13.913 4.5 14.2505 4.1625 14.2505 3.75C14.2505 3.3375 13.913 3 13.5005 3Z"
                                    fill={
                                      theme === "dark" ? "#FFFFFF" : "#525256"
                                    }
                                  />
                                </svg>
                              </button>
                            </div>
                          </TableCell>
                        ) : column.id === "logo" ? (
                          <TableCell key={index}>
                            <div className="flex ">
                              <img
                                src={row.logo}
                                alt="Logo"
                                width="40"
                                height="40"
                              />
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell key={column.id}>
                            {row[column.id]}{" "}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="flex justify-center  items-center my-[20px] p-2 mx-[35px]">
          {/* <div className="text-[#B5B7C0] text-[16px] font-[500]">
            Showing 1 to 8 of {boards.length} boards
          </div> */}
          <Pagination
            count={Math.ceil(boards.length / rowsPerPage)}
            page={page + 1}
            shape="rounded"
            variant="outlined"
            onChange={(event, value) => {
              handleChangePage(event, value - 1);
            }}
          />
        </div>
      </Paper>

      {/* Add Board */}
      <AddBoardModal
        open={open}
        handleClose={handleClose}
        style={style}
        handleImageUpload={handleImageUpload}
        selectedImage={selectedImage}
      />

      <CustomSnackbar
        openSnackbar={showSuccessDelete}
        closeSnackbar={() => setShowSuccessDelete(false)}
        message="Board deleted successfully"
      />

      {/* Update Board */}
      <UpdateBoardModal
        open={openUpdate}
        handleClose={handleCloseUpdate}
        style={style}
        formData={formData}
        onUpdateFormData={handleUpdateFormData}
        selectedBoard={selectedBoard}
        handleChange={handleChange}
        handleImageUpload={handleImageUpload}
        selectedImage={selectedImage}
      />

      {/* Delete Board */}

      <DeleteBoardModal
        open={openDelete}
        handleClose={handleCloseDelete}
        style={style}
        handleDeleteBoard={handleDeleteBoard}
        boardId={selectedBoard}
      />
    </div>
  );
};

export default BoardsPage;
