import React, { useState, useEffect, useContext } from "react";
import { Modal, Box, Switch, styled, FormControlLabel } from "@mui/material";
import axios from "axios";
import { useUser } from "../../../state/UserContext";
import { ThemeContext } from "../../../state/ThemeContext";
import IOSSwitch from "../../IosSwitch";
const UpdateSensorModal = ({
  openUpdate,
  handleCloseUpdate,
  style,
  sensor,
}) => {
  const { userData } = useUser();
  const [isRealtime, setIsRealtime] = useState(false);
  const [isSevere, setIsSevere] = useState(false);
  const [isHistorical, setIsHistorical] = useState(false);
  const [updatedSensor, setUpdatedSensor] = useState(sensor);

  useEffect(() => {
    setUpdatedSensor(sensor);
    setIsSevere(sensor.is_severe);
    setIsHistorical(sensor.is_historical);
    setIsRealtime(sensor.is_realtime);
  }, [sensor]);

  const theme = useContext(ThemeContext).theme;
  const sensorsOptions = [
    "temperature",
    "humidity",
    "pressure",
    "altitude",
    "Accelerometer",
    "Orientation",
    "UV",
    "Light",
    "Motion",
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedSensor((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [userBoards, setUserBoards] = useState([]);

  const fetchBoards = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token

      };
      //get user's boards list
      const url = `${process.env.REACT_APP_SERVER_URL}/boards/by-user/${userData.id}`;
      const response = await axios.get(url, { headers });
      setUserBoards(response.data.boards);
    } catch (error) {
      console.error("Error fetching boards:", error);
    }
  };
  useEffect(() => {
    fetchBoards();
  }, []);

  useEffect(() => {
    setUpdatedSensor((prevState) => ({
      ...prevState,
      is_realtime: isRealtime,
      is_severe: isSevere,
      is_historical: isHistorical,
    }));
  }, [isRealtime, isSevere, isHistorical]);

  const handleUpdateSensor = async (newSensor) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token is missing");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ` + token

      };
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/sensors/${newSensor.id}`,
        newSensor, { headers }
      );

      if (response.status === 200) {
        handleCloseUpdate();
      }
    } catch (error) {
      console.error("Error Updating sensor", error);
    }
  };

  return (
    <Modal
      open={openUpdate}
      onClose={handleCloseUpdate}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <Box sx={style}>
        <div className="flex justify-between items-center mb-2">
          <h3
            className={` font-[700] text-[28px]  ${theme === "dark" ? "text-white" : "text-black"
              } `}
          >
            Update sensor
          </h3>
          <button type="button" onClick={handleCloseUpdate}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="24"
              viewBox="0 0 27 24"
              fill="none"
            >
              <path
                d="M20.25 5.93091L6.75 17.3185"
                stroke="#525256"
                strokeWidth="2.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.75 5.93091L20.25 17.3185"
                stroke="#525256"
                strokeWidth="2.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className="bg-[#EFF0EF] mb-[15px]" style={{ height: "2px" }} />
        <div>
          <form className="flex flex-col">
            <label
              htmlFor="type"
              className={`my-3 text-[15px] font-[500] ${theme === "dark" ? "text-white" : "text-black"
                }`}
            >
              Choose sensor type
            </label>
            <select
              name="sensor_type"
              id="sensor_type"
              form="carform"
              className={`rounded-[8px]  mb-[10px] px-3 focus:outline-none h-[54px] ${theme === "dark"
                ? "bg-[#4A4A4A]"
                : "bg-white border border-[#D0D5DD]"
                } `}
              value={updatedSensor.sensor_type}
              onChange={handleChange}
            >
              {sensorsOptions.map((option, index) => (
                <option
                  key={index}
                  value={option.toLowerCase().replace(/\s+/g, "-")}
                >
                  {option}
                </option>
              ))}
            </select>
            <label
              htmlFor="name"
              className={`my-3 text-[15px] font-[500] ${theme === "dark" ? "text-white" : "text-black"
                }`}
            >
              Sensor name
            </label>
            <input
              label="Name"
              name="name"
              fullWidth
              value={updatedSensor.name}
              onChange={handleChange}
              className={`rounded-[8px]  mb-[10px] px-3 focus:outline-none h-[54px] ${theme === "dark"
                ? "bg-[#4A4A4A]"
                : "bg-white border border-[#D0D5DD]"
                } `}
            />
            <label
              htmlFor="desc"
              className={`my-3 text-[15px] font-[500] ${theme === "dark" ? "text-white" : "text-black"
                }`}
            >
              Description
            </label>
            <textarea
              label="Desc"
              name="description"
              sensors="3"
              fullWidth
              value={updatedSensor.description}
              onChange={handleChange}
              className={`rounded-[8px]  mb-[10px] px-3 focus:outline-none h-[54px] ${theme === "dark"
                ? "bg-[#4A4A4A]"
                : "bg-white border border-[#D0D5DD]"
                } `}
            ></textarea>
            {/* <label
                            htmlFor="unit"
                            className={`my-3 text-[15px] font-[500] ${theme === "dark" ? "text-white" : "text-black"}`}
                        >
                            Unit type
                        </label>
                        <input
                            label="Unit"
                            name="unit"
                            fullWidth
                            value={updatedSensor.unit}
                            onChange={handleChange}
                            className={`rounded-[8px] bg-input_bg border-input_border border px-3 focus:outline-none h-[50px] ${theme === "dark" ? "bg-[#4A4A4A] text-white" : "bg-[#F7FBF7] border border-[#168004]"} `} /> */}
            {/* <label
                            htmlFor="range"
                            className="my-3 text-[15px] font-[500] text-[#344056]"
                        >
                            Range
                        </label>
                        <input
                            label="Range"
                            name="range"
                            fullWidth
                            value={sensor.range}
                            onChange={handleChange}
                            className="rounded-[8px] bg-input_bg border-input_border border  px-3 focus:outline-none h-[50px]"
                        /> */}
            <label
              htmlFor="board"
              className={`my-3 text-[15px] font-[500] ${theme === "dark" ? "text-white" : "text-black"
                }`}
            >
              Connect to
            </label>
            <select
              name="board"
              id="board"
              form="board"
              fullWidth
              className={`rounded-[8px]  mb-[10px] px-3 focus:outline-none h-[54px] ${theme === "dark"
                ? "bg-[#4A4A4A]"
                : "bg-white border border-[#D0D5DD]"
                } `}
              value={updatedSensor.board}
              onChange={handleChange}
            >
              {userBoards.map((board) => (
                <option key={board.id} value={board.id}>
                  {board.name}
                </option>
              ))}
            </select>
            <div
              className={`flex flex-wrap pt-8 ${theme === "dark" ? "text-white" : "text-black"
                }`}
            >
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={!!updatedSensor.is_realtime}
                    onChange={() => setIsRealtime((prevCheck) => !prevCheck)}
                  />
                }
                label="Realtime data"
                className="mb-2"
              />

              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={!!updatedSensor.is_severe}
                    onChange={() => setIsSevere((prevCheck) => !prevCheck)}
                  />
                }
                label="Critical sensor"
                className="mb-2"
              />

              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={!!updatedSensor.is_historical}
                    onChange={() => setIsHistorical((prevCheck) => !prevCheck)}
                  />
                }
                label="Save history"
                className="mb-2"
              />
            </div>

            <div className="flex flex-wrap justify-end  mb-[45px]">
              <div className="flex flex-wrap gap-[16px] modal-cancel w-full lg:w-auto">
                {" "}
                <button
                  className="bg-[#D9D9D9] rounded-[10px] px-[16px] py-[10px] w-full lg:w-28 text-black "
                  type="button"
                  onClick={handleCloseUpdate}
                >
                  Cancel
                </button>
                <button
                  className=" text-white rounded-[10px] px-[16px] py-[10px] w-full lg:w-28"
                  type="button"
                  onClick={() => handleUpdateSensor(updatedSensor)}
                  style={{
                    backgroundColor:
                      localStorage.getItem("theme-color") ||
                      userData.themeColor,
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default UpdateSensorModal;
