import React from "react";
import { useNavigate } from "react-router";
import Error from "../../assets/images/Error.svg";
import Next from "../../assets/images/next-icon.svg";
const Page404 = () => {
  const navigate = useNavigate();
  return (
    <div className="h-full flex flex-col justify-center items-center">
      <img src={Error} alt="404-error" className="" />
      <button
        className="px-5 flex  py-3 mt-6 bg-dark_green text-white rounded-[10px] sm:text-[17px] text-[15px]transition-all duration-75 active:scale-90"
        type="button"
        onClick={() => navigate("/home-dashboard")}
      >
        Go to Sensy32.io
        <img src={Next} alt="next-icon" className="ml-3 mt-1" />
      </button>
    </div>
  );
};

export default Page404;
