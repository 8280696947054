import React, { useState, useEffect, useContext, useRef } from "react";
import { ThemeContext } from "../../state/ThemeContext";
import { useUser } from "../../state/UserContext";
import meteo from "../../assets/images/meteo.svg";
import GeneralButton from "../../components/Button";
import TemperatureIcon from "../../assets/images/temperature-icon.svg";
import prevWidget from "../../assets/icons/prevSlide.svg";
import nextWidget from "../../assets/icons/nextSlide.svg";
import HideIcon from "../../assets/images/hide-icon.svg";
import IOSSwitch from "../../components/IosSwitch";
import PasswordIcon from "../../assets/icons/closed_eye.svg";
import PasswordIconWhite from "../../assets/icons/closed_eye_white.svg";
import active from "../../assets/images/active-icon.svg";
import inactive from "../../assets/images/inactive-icon.svg";
import { fetchBoards } from "../../services/boardService";
import { fetchSensors } from "../../services/sensorService";
import axios from "axios";
import { getLogsByUser } from "../../services/userLogsService";
import { getUserById } from "../../services/userService";
import { Link } from 'react-router-dom';
import AddBoardModal from "../../components/modals/boards/AddBoardModal";
import { getCustomDashByUser, getWidgetsFromCustomDashboard } from "../../services/customDashboardService";
import customize_icon from "../../assets/images/customize_icon.svg";
import CustomizeDashboardModal from "../../components/modals/dashboards/CustomizeDashboardModal";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { DndProvider, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableChart from "../../components/DraggableChart";
import { clearWidgetFromHome } from "../../services/widgetService";
import CustomSnackbar from "../../components/CustomSnackbar";
import { CircularProgress } from "@mui/material";
import { getTimeDifference } from "../../utils/getTimeDifference";
const HomeDashboard = () => {
    const { userData } = useUser();
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // beforeChange: (current, next) => setCurrentSlide(next),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    let sliderRef = useRef(null);

    const handlePrevious = () => {
        sliderRef.slickPrev();
    }
    const handleNext = () => {
        sliderRef.slickNext();
    };
    const { theme } = useContext(ThemeContext);
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "600px", // Default width for mobile screens
        borderRadius: "16px",
        color: theme === "dark" ? "white" : "black",
        backgroundColor: theme === "dark" ? "#1E1E1E" : "#FFFFFF",
        boxShadow: 24,
        paddingTop: 3,
        paddingLeft: "30px",
        paddingRight: "30px",
    };
    if (window.matchMedia("(min-width: 768px)").matches) {
        style.width = "40%";
    }
    const [isHidden, setIsHidden] = useState(false);
    const toggleHidden = () => {
        setIsHidden(!isHidden);
    };
    const LoadingIndicator = () => (
        <div className="flex justify-center items-center pt-20">
            <CircularProgress
                style={{
                    color: localStorage.getItem("theme-color") || userData.themeColor,
                }}
            />
        </div>
    );
    const getBoardNameById = (boardId) => {
        const board = boards.find((item) => item.id === boardId);
        return board ? board.name : null;
    };

    const [clearWidgetSnackbar, setClearWidgetSnackbar] = useState(false);
    const [boards, setBoards] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [activeBoards, setActiveBoards] = useState(0);
    const [inactiveBoards, setInactiveBoards] = useState(0);
    const [activeSensors, setActiveSensors] = useState(0);
    const [inactiveSensors, setInactiveSensors] = useState(0);
    const [userLogs, setUserLogs] = useState([]);
    const [error, setError] = useState(null);
    const [userInfo, setUserInfo] = useState([]);
    const [selectedImage, setSelectedImage] = useState([]);
    const [open, setOpen] = useState(false);
    const [lat, setLat] = useState([]);
    const [long, setLong] = useState([]);
    const [weatherData, setWeatherData] = useState([]);
    const [forecast, setForecast] = useState([]);
    const [userLocation, setUserLocation] = useState([]);
    const [timeZone, setTimeZone] = useState([]);
    const [widgets, setWidgets] = useState([]);
    const [dashboardWidgets, setDashboardWidgets] = useState([]);
    const [openDashboardModal, setOpenDashboardModal] = useState(false);
    const [customizedDashboard, setCustomizedDashboard] = useState([]);
    const [isCurrentDateVisible, setIsCurrentDateVisible] = useState(true);
    const [isCurrentLocationVisible, setIsCurrentLocationVisible] = useState(true);
    const [isActiveBoardsVisible, setIsActiveBoardsVisible] = useState(true);
    const [isInActiveBoardsVisible, setIsInActiveBoardsVisible] = useState(true);
    const [isActiveSensorsVisible, setIsActiveSensorsVisible] = useState(true);
    const [isInActiveSensorsVisible, setIsInActiveSensorsVisible] = useState(true);
    const [isBoardsVisible, setIsBoardsVisible] = useState(true);
    const [isWidgetsVisible, setIsWidgetsVisible] = useState(true);
    const [isLogsVisible, setIsLogsVisible] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const handleSave = (
        newIsCurrentDateVisible,
        newIsCurrentLocationVisible,
        newIsActiveBoardsVisible,
        newIsInActiveBoardsVisible,
        newIsActiveSensorsVisible,
        newIsInActiveSensorsVisible,
        newIsBoardsVisible,
        newIsWidgetsVisible,
        newIsLogsVisible
    ) => {
        setIsCurrentDateVisible(newIsCurrentDateVisible);
        setIsCurrentLocationVisible(newIsCurrentLocationVisible);
        setIsCurrentDateVisible(newIsCurrentDateVisible);
        setIsActiveBoardsVisible(newIsActiveBoardsVisible);
        setIsInActiveBoardsVisible(newIsInActiveBoardsVisible);
        setIsActiveSensorsVisible(newIsActiveSensorsVisible);
        setIsInActiveSensorsVisible(newIsInActiveSensorsVisible);
        setIsBoardsVisible(newIsBoardsVisible);
        setIsWidgetsVisible(newIsWidgetsVisible);
        setIsLogsVisible(newIsLogsVisible);
    };
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleOpen = () => setOpen(true);
    const handleOpenDashoardModal = () => setOpenDashboardModal(true);
    const handleCloseDashoardModal = async (newBoard) => {
        setOpenDashboardModal(false);
    };
    const handleClose = async (newBoard) => {
        setOpen(false);
        fetchMyBoards();
    };
    const fetchLogs = async () => {
        try {
            const logsData = await getLogsByUser(userData.id);
            setUserLogs(logsData);
        } catch (error) {
            setError(error.message);
        }
    };
    const fetchUserInfo = async () => {
        try {
            const userInfo = await getUserById(userData.id);
            setUserInfo(userInfo);
        } catch (error) {
            console.error("Error fetching user information:", error);
            setError("Error fetching user information. Please try again.");
        }
    };
    const fetchCustomDashboard = async () => {
        try {
            const userId = userData.id;
            const customizedDashboardData = await getCustomDashByUser(userId);
            setCustomizedDashboard(customizedDashboardData);
            setIsCurrentDateVisible(customizedDashboard.currentDate);
            setIsCurrentLocationVisible(customizedDashboard.currentLocation);
            setIsActiveBoardsVisible(customizedDashboard.activeBoards);
            setIsInActiveBoardsVisible(customizedDashboard.inactiveBoards);
            setIsActiveSensorsVisible(customizedDashboard.activeSensors);
            setIsInActiveSensorsVisible(customizedDashboard.inactiveSensors);
            setIsBoardsVisible(customizedDashboard.myBoards);
            setIsWidgetsVisible(customizedDashboard.widgets_visible);
            setIsLogsVisible(customizedDashboard.activityLogs);
        } catch (error) {
            console.error('Error fetching customized dashboard:', error);
        }
    };
    const fetchWidgets = async () => {
        try {
            const widgetsData = await getWidgetsFromCustomDashboard(userData.id);
            setWidgets(widgetsData);
        } catch (error) {
            setError(error.message);
        }
    };

    const fetchHomeWidgets = async () => {
        try {
            await fetchWidgets(userData.id);
        } catch (error) {
            console.error("Error fetching widgets:", error);
        }
    };
    const hideWidget = async (widgetId) => {
        const widgetCleared = await clearWidgetFromHome(widgetId, userData.id);
        if (widgetCleared) {
            setClearWidgetSnackbar(true);
            const updatedWidgets = widgets.filter((widget) => widget.id !== widgetId);
            setWidgets(updatedWidgets);
        }
    }
    const fetchMyBoards = async () => {
        try {
            const boardsData = await fetchBoards(userData.id);
            setBoards(boardsData);
        } catch (error) {
            console.error("Error fetching boards:", error);
        }
    };

    const handleSwitchToggle = async (boardId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error("Token is missing");
                return;
            }
            const headers = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ` + token

            };

            const updatedBoards = boards.map((board) =>
                board.id === boardId
                    ? { ...board, is_realtime: !board.is_realtime }
                    : board
            );
            setBoards(updatedBoards);
            await axios.put(`${process.env.REACT_APP_SERVER_URL}/boards/${boardId}`, {
                is_realtime: updatedBoards.find((board) => board.id === boardId)
                    .is_realtime,
            }, { headers });
        } catch (error) {
            console.error("Error updating board status:", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // setIsLoading(true);
                await Promise.all([
                    fetchHomeWidgets(),
                    fetchMyBoards(),
                    fetchLogs(),
                    fetchUserInfo(),
                    fetchCustomDashboard()
                ]);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [isLoading]);

    useEffect(() => {
        if (boards.length > 0) {
            const activeUserBoards = boards.filter(board => board.is_realtime === true).length;
            setActiveBoards(activeUserBoards);
            setInactiveBoards(boards.length - activeUserBoards);
        }
        if (sensors.length > 0) {
            const activeUserSensors = sensors.filter(sensor => sensor.is_realtime === true).length;
            setActiveSensors(activeUserSensors);
            setInactiveSensors(sensors.length - activeUserSensors);
        }

    }, [boards, sensors]);

    const fetchUserSensors = async () => {
        const userSensors = await fetchSensors(userData.id);
        setSensors(userSensors);
    }
    useEffect(() => {
        fetchUserSensors();
    }, []);


    return (
        <div className=" mx-[32px] mt-[36px]">
            {isLoading ? (
                <LoadingIndicator />
            ) : <div>
                <div className="flex flex-col lg:flex-row  justify-between">
                    <div className='flex flex-col md:flex-row items-start'>
                        <h2 className={`text-[25px] font-[600] ${theme === "dark" ? "text-white" : "text-black"}`}>
                            Home
                        </h2>
                    </div>
                    <div className='flex flex-col md:flex-row md:items-end md:justify-end gap-2'>
                        <button
                            className="flex md:flex  md:mt-0 items-center justify-center gap-1 bg-[#F2F2F2] text-white rounded-[10px] sm:text-[16px] text-[15px] font-[500] px-[1rem] py-[2px] sm:h-[45px] h-[45px] transition-all duration-75 active:scale-90 flex-grow"
                            type="button"
                            onClick={handleOpenDashoardModal}
                        >
                            <span className="text-black text-[14px]">Customize</span>
                            <img src={customize_icon} alt="customize-icon" className="ml-2" />
                        </button>

                        <div className='flex md:items-end md:justify-end'>
                            <GeneralButton
                                onClick={handleOpen}
                                backgroundColor={
                                    localStorage.getItem("theme-color") || userData.themeColor
                                }
                                className="mt-8 md:mt-0">
                                Create
                            </GeneralButton>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row gap-3 mt-4">
                    <div className="lg:w-2/3 w-full mb-4 lg:mb-0 gap-4 flex flex-col">
                        {isBoardsVisible && (
                            <div>
                                <div className="flex justify-between">
                                    <h2 className={`text-[20px] font-[600] ${theme === "dark" ? "text-white" : "text-black"}`}>
                                        My Boards
                                    </h2>
                                </div>
                                {boards.length == 0 &&
                                    <p className={`flex text-[18px] font-[600] items-center justify-center`}
                                        style={{
                                            color:
                                                localStorage.getItem("theme-color") ||
                                                userData.themeColor,
                                        }}
                                    >No Boards Found.</p>}

                                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 mt-4">
                                    {boards.map((board, index) => (
                                        <div key={index} className="rounded-[20px] border border-[#E6E5F2] lg:max-w-[230px] p-4">
                                            <div className="flex justify-between">
                                                <p className={`text-[16px] font-[500] mt-2 ${theme === "dark" ? "text-white" : "text-black"}`}>{board.is_realtime ? 'ON' : 'OFF'}</p>
                                                <IOSSwitch
                                                    sx={{ m: 1 }}
                                                    checked={board.is_realtime}
                                                    onChange={() => handleSwitchToggle(board.id)}
                                                />
                                            </div>
                                            <div className="max-h-[50px]">
                                                <img src={board.logo} alt={board.name} className="w-12 h-12" />
                                            </div>
                                            <p className="text-[16px] font-[500px] text-[#168004]">{board.name}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {isWidgetsVisible && (
                            <div>
                                <div className="flex justify-between items-center mb-4">
                                    <h2 className={`text-[20px] font-[600] ${theme === "dark" ? "text-white" : "text-black"}`}>
                                        My Widgets
                                    </h2>
                                </div>
                                {widgets.length === 0 && <p className={`flex text-[18px] font-[600] items-center justify-center`}
                                    style={{
                                        color:
                                            localStorage.getItem("theme-color") ||
                                            userData.themeColor,
                                    }}
                                >No widgets displayed here.</p>}
                                <DndProvider backend={HTML5Backend}>
                                    <div className="grid grid-cols-12 gap-4 mt-8">
                                        {widgets.length != 0 &&
                                            <div className="flex items-center justify-center">
                                                <button onClick={handlePrevious}>
                                                    <img src={prevWidget} alt="Previous Widget" />
                                                </button>
                                            </div>}
                                        <div className="col-span-10">
                                            <div className="slider-container" style={{ width: '100%' }}>
                                                <Slider {...settings} ref={(slider) => { sliderRef = slider; }}>
                                                    {widgets.map((chart) => (
                                                        <div key={chart.id} className="flex flex-row items-center justify-center w-full">

                                                            <div className="flex flex-row justify-end items-center">
                                                                {theme === 'dark' ? (
                                                                    <img
                                                                        src={PasswordIconWhite}
                                                                        alt="Hide Icon"
                                                                        className="mr-2"
                                                                    />) :
                                                                    (
                                                                        <img
                                                                            src={PasswordIcon}
                                                                            alt="Hide Icon"
                                                                            className="mr-2"
                                                                        />)}
                                                                <div className="" onClick={() => hideWidget(chart.id)}>
                                                                    <p className={`text-lg font-bold cursor-pointer ${theme === "dark" ? "text-white" : "text-black"}`}>Hide</p>
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center">
                                                                {chart && (
                                                                    <DraggableChart
                                                                        key={chart.id}
                                                                        chart={chart}
                                                                        sensor={chart.sensor}
                                                                        boardName={getBoardNameById(chart.board)}
                                                                        fromHome={true}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Slider>
                                            </div>
                                        </div>
                                        {widgets.length != 0 &&
                                            <div className="flex items-center justify-center">
                                                <button onClick={handleNext}>
                                                    <img src={nextWidget} alt="Next Widget" />
                                                </button>
                                            </div>}
                                    </div>
                                </DndProvider>

                            </div>
                        )}

                    </div>
                    {
                        (isActiveSensorsVisible || isInActiveSensorsVisible || isLogsVisible || isActiveBoardsVisible || isInActiveBoardsVisible) && (
                            <div className={`lg:w-1/3 w-full rounded-lg shadow-md  p-4  ${theme === "dark" ? "bg-[#262626]" : "bg-[#F4F5FB]"}`}>
                                <div className="flex flex-col gap-4 mt-4">
                                    {(isActiveBoardsVisible || isInActiveBoardsVisible) && (

                                        <div className={`rounded-[13px] p-4 ${theme === "dark" ? "bg-[black]" : "bg-[#FFF]"}`}>
                                            {(isActiveBoardsVisible || isInActiveBoardsVisible) && (
                                                <div className="flex justify-between">
                                                    <h2 className={`text-[20px] font-[600] ${theme === "dark" ? "text-white" : "text-black"}`}>
                                                        Boards
                                                    </h2>
                                                    <Link to="/manage-boards" className="text-[#168004] text-[16px] font-[400]">
                                                        View All
                                                    </Link>
                                                </div>)}
                                            <div className="flex flex-col sm:flex-row mt-3">
                                                {isActiveBoardsVisible && (
                                                    <div className="flex flex-1 mt-4 sm:mt-0">
                                                        <img src={active} className="w-10 h-10" />
                                                        <div className="flex flex-col gap-3">
                                                            <p className="ml-2 text-[#808191] text-[18px] font-[600]">Active</p>
                                                            <span className={`ml-2 font-[700] text-[30px] ${theme === "dark" ? "text-white" : "text-black"}`}>{activeBoards}</span>
                                                        </div>
                                                    </div>
                                                )}
                                                {isActiveBoardsVisible && isInActiveBoardsVisible && (
                                                    <div className="bg-gray-200 w-[2px] mr-4"></div>
                                                )}
                                                {isInActiveBoardsVisible && (
                                                    <div className="flex flex-1 items-start justify-start mt-4 sm:mt-0  ">
                                                        <img src={inactive} className="w-10 h-10" />
                                                        <div className="flex flex-col gap-3">
                                                            <p className="ml-2 text-[#808191] text-[18px] font-[600]">Inactive</p>
                                                            <span className={`ml-2 font-[700] text-[30px] ${theme === "dark" ? "text-white" : "text-black"}`}>{inactiveBoards}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {(isActiveSensorsVisible || isInActiveSensorsVisible) && (
                                        <div className={`rounded-[13px] p-4 ${theme === "dark" ? "bg-black" : "bg-[#FFF]"}`}>
                                            {(isActiveSensorsVisible || isInActiveSensorsVisible) && (
                                                <div className="flex justify-between">
                                                    <h2 className={`text-[20px] font-[600] ${theme === "dark" ? "text-white" : "text-black"}`}>
                                                        Sensors
                                                    </h2>
                                                    <Link to="/manage-sensors" className="text-[#168004] text-[16px] font-[400]">
                                                        View All
                                                    </Link>
                                                </div>
                                            )}
                                            <div className="flex flex-col sm:flex-row  mt-3">
                                                {isActiveSensorsVisible && (
                                                    <div className="flex flex-1 mt-4 sm:mt-0">
                                                        <img src={active} className="w-10 h-10" />
                                                        <div className="flex flex-col gap-3">
                                                            <p className="ml-2 text-[#808191] text-[18px] font-[600]">Active</p>
                                                            <span className={`ml-2 font-[700] text-[30px] ${theme === "dark" ? "text-white" : "text-black"}`}>{activeSensors}</span>
                                                        </div>
                                                    </div>
                                                )}
                                                {isInActiveSensorsVisible && isActiveSensorsVisible && (
                                                    <div className="bg-gray-200 w-[2px] mr-4"></div>
                                                )}
                                                {isInActiveSensorsVisible && (
                                                    <div className="flex flex-1 items-start justify-start mt-4 sm:mt-0  ">
                                                        <img src={inactive} className="w-10 h-10" />
                                                        <div className="flex flex-col gap-3">
                                                            <p className="ml-2 text-[#808191] text-[18px] font-[600]">Inactive</p>
                                                            <span className={`ml-2 font-[700] text-[30px] ${theme === "dark" ? "text-white" : "text-black"}`}>{inactiveSensors}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {isLogsVisible && (
                                        <div className={`rounded-[13px] p-4 ${theme === "dark" ? "bg-black" : "bg-[#FFF]"}`}>
                                            <div className="flex justify-between">
                                                <h2 className={`text-[20px] font-[600] ${theme === "dark" ? "text-white" : "text-black"}`}>
                                                    Activity Logs
                                                </h2>
                                                <Link to="/activity-log" className="text-[#168004] text-[16px] font-[400]">
                                                    View All
                                                </Link>
                                            </div>

                                            <div className="mt-6">
                                                {userLogs.slice(0, 5).map((log, index) => (
                                                    <div key={index} className=" mt-3 flex justify-between">
                                                        <div className="flex">
                                                            <div className="flex flex-col">
                                                                <div className="flex">
                                                                    <div className="rounded-[50%] w-8 h-8">
                                                                        <img src={userData.picture} alt="user-picture" />
                                                                    </div>
                                                                    <div className="ml-3">
                                                                        <div className={`text-[14px] font-[400] ${theme === "dark" ? "text-white" : "text-[#141522]"}`}>{userData.firstName}</div>
                                                                        <div className="text-[14px] text-[#9C9CA4] font-[400]">{log.action}</div>
                                                                        <div className="text-[14px] text-[#9C9CA4] font-[400]">{getTimeDifference(log.updated_at)}</div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    }
                </div >
            </div >
            }
            <AddBoardModal
                open={open}
                handleClose={handleClose}
                style={style}
                handleImageUpload={handleImageUpload}
                selectedImage={selectedImage}
            />
            <CustomizeDashboardModal
                open={openDashboardModal}
                style={style}
                handleClose={handleCloseDashoardModal}
                initialIsCurrentDateVisible={isCurrentDateVisible}
                initialIsActiveBoardsVisible={isActiveBoardsVisible}
                initialIsInActiveBoardsVisible={isInActiveBoardsVisible}
                initialIsInActiveSensorsVisible={isInActiveSensorsVisible}
                initialIsActiveSensorsVisible={isActiveSensorsVisible}
                initialIsWidgetsVisible={isWidgetsVisible}
                initialIsBoardsVisible={isBoardsVisible}
                initialIsLogsVisible={isLogsVisible}
                initialIsCurrentLocationVisible={isCurrentLocationVisible}
                onSave={handleSave}
            />
            <CustomSnackbar
                openSnackbar={clearWidgetSnackbar}
                closeSnackbar={() => {
                    setClearWidgetSnackbar(false);
                }}
                message="Widget hidden from your home page."
            />
        </div >

    );
};

export default HomeDashboard;
