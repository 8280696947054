import axios from "axios";

const updateUserProfile = (userData, formData, setUser) => {
  const updatedUserData = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    themeColor: formData.theme,
    country: formData.country,
    phoneNumber: formData.phoneNumber,
    picture: formData.picture,
    unitType: formData.unitType
  };
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token is missing");
    return;
  }
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ` + token

  };
  return axios
    .put(
      `${process.env.REACT_APP_SERVER_URL}/user/update-profile/${userData.id}`,
      updatedUserData, { headers }
    )
    .then((response) => {
      window.localStorage.setItem("user", JSON.stringify(response.data.user));
      setUser(response.data.user);
      return response.data.user;
    })
    .catch((error) => {
      console.error("Error updating profile:", error);
      throw error; // Rethrow the error for the caller to handle
    });
};

const setAccountColor = (color, userData, formData, setFormData, setTheme) => {
  // Update the theme color in the document root
  setTheme(color);
  localStorage.setItem("theme-color", color);
  // Update userData.themeColor and formData.theme
  const updatedThemeData = {
    ...formData,
    theme: color,
  };

  setFormData(updatedThemeData);

  // Update the user's themeColor in userData
  const updatedUserData = {
    ...userData,
    themeColor: color,
  };

  updateUserProfile(userData, updatedThemeData)
    .catch((error) => {
      console.error("Error updating profile:", error);
    });

  switch (color) {
    case "#168004":
      localStorage.setItem("theme-color-light", "rgba(22, 128, 4, 0.65)");
      localStorage.setItem("theme-color-input", "#F7FBF7");
      break;
    case "#48CAE4":
      localStorage.setItem("theme-color-light", "#38A3A5");
      localStorage.setItem("theme-color-input", "#EDF6F9");
      break;
    case "#E63946":
      localStorage.setItem("theme-color-light", "#F94144");
      localStorage.setItem("theme-color-input", "#FEC5BB");
      break;
    case "#7B2CBF":
      localStorage.setItem("theme-color-light", "#C77DFF");
      localStorage.setItem("theme-color-input", "#F1D6FF");
      break;
    case "#000000":
      localStorage.setItem("theme-color-light", "#293241");
      localStorage.setItem("theme-color-input", "#E9ECEF");
      break;
  }
};
const getUserById = async (userId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ` + token

    };
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/${userId}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching user by ID:', error.message);
    throw error;
  }
};

const changeUserPassword = async (userData, oldPassword, newPassword, setUser) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ` + token

    };

    const userId = userData.id;
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/user/change-password`,
      {
        oldPassword,
        newPassword,
        userId
      },
      {
        headers
      }
    );

    if (response.status === 200 && userData.withGoogle) {
      setUser({ ...userData, withGoogle: false });
    }

    return response.status === 200;
  } catch (error) {
    console.error("Error occurred when trying to update password", error);
    return false;
  }
}


export { updateUserProfile, setAccountColor, getUserById, changeUserPassword };
