import React from "react";
import styles, { typo } from "../../../style";
import esp32 from "../../../assets/images/esp32.svg";
import "./description.css";
import { useNavigate } from "react-router";

const Description = () => {
    const navigate = useNavigate();

  return (
    <section
      className={`bg-[#FAFDFA] mt-[100px] pt-[20px] flex flex-col items-center justify-center`}
    >
      <div className={`section2-content ${styles.paddingX}`}>
        <h2 className={`${typo.h3} mt-[20px]`}>Unveiling Sensy32</h2>

        <div className="flex justify-between items-center flex-wrap">
          <div className="flex flex-col w-full lg:w-1/2 mt-10 lg:mt-0">
            <div className="flex items-center gap-3">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="51"
                height="12"
                viewBox="0 0 51 12"
                fill="none"
                className="hidden sm:block"
              >
                <path
                  d="M1 5.42648C0.585786 5.42648 0.25 5.76227 0.25 6.17648C0.25 6.5907 0.585786 6.92648 1 6.92648L1 5.42648ZM50.5303 6.70682C50.8232 6.41393 50.8232 5.93905 50.5303 5.64616L45.7574 0.873188C45.4645 0.580295 44.9896 0.580295 44.6967 0.873188C44.4038 1.16608 44.4038 1.64095 44.6967 1.93385L48.9393 6.17649L44.6967 10.4191C44.4038 10.712 44.4038 11.1869 44.6967 11.4798C44.9896 11.7727 45.4645 11.7727 45.7574 11.4798L50.5303 6.70682ZM1 6.92648L25.5 6.92649L25.5 5.42649L1 5.42648L1 6.92648ZM25.5 6.92649L37.75 6.92649L37.75 5.42649L25.5 5.42649L25.5 6.92649ZM37.75 6.92649L43.875 6.92649L43.875 5.42649L37.75 5.42649L37.75 6.92649ZM43.875 6.92649L50 6.92649L50 5.42649L43.875 5.42649L43.875 6.92649Z"
                  fill="#111111"
                />
              </svg>
              <p className="text-[#191c32] text-[20px] sm:text-[24px] font-[500] font-Inter text-left">
                The ultimate ESP32 development board that's
              </p>
            </div>
            <p className="text-[#191c32] text-[20px] sm:text-[24px] font-[500] font-Inter text-left">
              revolutionizing the way we interact with the world around us.
              Packed with an array of sensors and backed by a robust ESP32-S3
              Wifi and Bluetooth module, Sensy32 is not just a board, it's a
              gateway to endless possibilities.
            </p>

            <button className="bg-[#168004] mt-[52px] px-[24px] py-[14px] text-white font-Inter font-[600] flex items-center justify-center gap-3 max-w-[186px] rounded-[8px]"   
              onClick={() => navigate("/register")}
            >
              <span>Sign Up Now</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13V11ZM20 12L20.7071 12.7071L21.4142 12L20.7071 11.2929L20 12ZM14.7071 5.29289C14.3166 4.90237 13.6834 4.90237 13.2929 5.29289C12.9024 5.68342 12.9024 6.31658 13.2929 6.70711L14.7071 5.29289ZM13.2929 17.2929C12.9024 17.6834 12.9024 18.3166 13.2929 18.7071C13.6834 19.0976 14.3166 19.0976 14.7071 18.7071L13.2929 17.2929ZM4 13H20V11H4V13ZM13.2929 6.70711L19.2929 12.7071L20.7071 11.2929L14.7071 5.29289L13.2929 6.70711ZM19.2929 11.2929L13.2929 17.2929L14.7071 18.7071L20.7071 12.7071L19.2929 11.2929Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <div>
            <img src={esp32} alt="esp32" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Description;
