import React from 'react';

const DateTimeDisplay = ({ value, type }) => {
    return (
        <div className='unit-box flex flex-col justify-center items-center space-y-3'>
            <p>{value}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="99" height="1" viewBox="0 0 99 1" fill="none">
                <line x1="0.0449829" y1="0.620234" x2="98.5934" y2="0.620225" stroke="#F2F2F2" strokeOpacity="0.21" strokeWidth="0.759563"/>
            </svg>
            <span>{type}</span>
        </div>
    );
};

export default DateTimeDisplay;
