import React, { useState } from "react";
import "./login.css";
import LoginBg from "../../assets/images/Img.png";
import logo from "../../logo.svg";
import PasswordIcon from "../../assets/icons/closed_eye.svg";
import EyeIcon from "../../assets/icons/opened_eye.svg";
import GoogleIcon from "../../assets/icons/google-logo.svg";
import { useNavigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useUser } from "../../state/UserContext";
import CustomSnackbar from "../../components/CustomSnackbar";
import { fetchNbOfBoards } from "../../services/boardService";
export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { userData, setUser } = useUser();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const googleAuth = async () => {
    try {
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/auth/google/callback`,
        "_self"
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  const loginUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/auth/login`,
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );
  
      if (response.status === 201) {
        const { user, token } = response.data;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token);
  
        if (!user.themeColor) {
          localStorage.setItem("theme-color", "#168004");
        } else {
          localStorage.setItem("user-color", JSON.stringify(user.themeColor));
        }
  
        try {
          const nbOfBoards = await fetchNbOfBoards(user.id);
          localStorage.setItem("nb_boards", nbOfBoards);
        } catch (error) {
          localStorage.setItem("nb_boards", 0);
          console.error("Error fetching number of boards:", error);
        }
  
        setTimeout(() => {
          setUser(user);
          navigate("/home-dashboard");
          window.location.reload();
        }, 1000);
      } else {
        setOpenSnackbar(true);
      }
    } catch (err) {
      console.error("Error:", err);
      setOpenSnackbar(true);
    }
  };
  

  return (
    <div className="w-full h-screen flex ">
      <div className="lg:w-1/2 w-full  h-full flex flex-col  mx-auto  background-image-login background-login">
        <div className=" flex flex-col justify-center h-full">
          <div className="flex flex-col justify-center items-center ">
            <img className="mt-10 lg:mt-12" src={logo} alt="Logo" />
          </div>

          <div className="flex flex-col items-center justify-center mt-8">
            <div className="flex justify-start items-start mt-3">
              <h1 className="login-title lg:mt-8 text-green-600 font-inter font-semibold text-[25px] sm:text-[30px] leading-6 sm:leading-8">
                Log In to Your Account
              </h1>
            </div>

            <form
              className="w-full mt-12 sm:w-full md:w-full sm:block px-12 sm:px-24 lg:px-12   space-y-4"
              onSubmit={loginUser}
            >
              {/**sm:justify-center sm:items-center  */}
              <div>
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <div className="flex input-container">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    className="w-full mt-1 mb-3 sm:w-full md:w-full form-input-register p-2 pr-10 border rounded-lg"
                    placeholder="Enter your email"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <div className="flex input-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    className="w-full mt-1 sm:w-full md:w-full form-input-register p-2 pr-10 border rounded-lg"
                    placeholder="Enter your password"
                  />
                  <div className="icon-container absolute inset-y-0 right-0 flex items-center pr-3">
                    <img
                      src={showPassword ? EyeIcon : PasswordIcon}
                      alt="Password Icon"
                      onClick={togglePasswordVisibility}
                      className="icon-auth cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center form-input-register">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="rememberMe"
                    className="form-checkbox h-4 w-4 "
                  />
                  <label htmlFor="rememberMe" className="form-label ml-2">
                    Remember Me
                  </label>
                </div>
                <a
                  href="#"
                  className="forgot-password"
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  Forgot Password ?
                </a>
              </div>
              <div className="relative  flex justify-center">
                <button
                  type="submit"
                  className=" mt-2 login-button rounded-lg p-2 w-full"
                >
                  Login
                </button>
              </div>
              <div className="flex justify-center items-center form-input-register  lg:hidden ">
                <div>
                  New to Sensy? <span> </span>
                  <a href="/register" className="forgot-password">
                    Sign Up
                  </a>
                </div>
              </div>
            </form>
            <div className="w-full flex  flex-col items-center px-12 sm:px-24 lg:px-12">
              <div className="flex justify-center items-center form-input-register my-3">
                <p className="social-media ">Or Login using</p>
              </div>
              {/* <img
                src={FacebookIcon}
                alt="Facebook"
                className="flex items-center p-1 py-2 rounded-lg"
              /> */}

              <button
                type="submit"
                onClick={googleAuth}
                className="flex items-center justify-center gap-2  text-black rounded-[10px] sm:text-[18px] text-[15px] font-[500] px-[30px] py-[8px] max-h-[55px] w-full mb-2   transition-all duration-75 active:scale-90"
                style={{
                  boxShadow: "0px 1.994px 5.982px 0px rgba(19, 18, 66, 0.07)",
                  border: "0.997px solid var(--Neutral-300, #EFF0F6)",
                }}
              >
                <img
                  src={GoogleIcon}
                  alt="Google"
                  height="33"
                  width="33"
                  className="flex items-center py-2 p-1 rounded-lg"
                />
                <span>Continue with Google</span>
              </button>
            </div>

            {/* <img
                src={TwitterIcon}
                alt="Twitter"
                className="flex items-center p-1 py-2 rounded-lg"
              /> */}
          </div>
        </div>
      </div>
      <div
        className="hidden  md:hidden image lg:block lg:w-1/2 h-full md:flex flex-col bg-cover"
        style={{ backgroundImage: `url(${LoginBg})` }}
      >
        <div className="flex flex-col items-center ml-16 justify-center h-full text-center sm:text-left">
          <h1 className="signup-text mt-6 sm:mt-12 p-2 text-white text-2xl sm:text-3xl">
            Don't Have an Account Yet?
          </h1>
          <p className="signup-text-details text-sm sm:text-base">
            Register in a few easy steps and experience our services quickly.
          </p>
          <button
            type="submit"
            className="signUp-button mt-4 sm:mt-5 rounded-lg p-2"
            onClick={() => navigate("/register")}
          >
            Sign Up
          </button>
        </div>
      </div>
      <CustomSnackbar
        openSnackbar={openSnackbar}
        closeSnackbar={() => {
          setOpenSnackbar(false);
        }}
        message="Incorrect credentials! Please try again."
      />
    </div>
  );
}
