import React, { useContext, useEffect, useState } from "react";
import { Modal, Box } from "@mui/material";
import { ThemeContext } from "../../../state/ThemeContext";
import { useUser } from "../../../state/UserContext";
import Avatars from '../../../assets/images/Avatar-group.svg';
import trash from '../../../assets/images/delete-member.svg';

import { addUserForDashboard, fetchDashboards, getUserByEmail, removeUserFromDashboard } from "../../../services/dashboardService";
import CustomSnackbar from "../../CustomSnackbar";

const InviteTeamModal = ({ open, handleClose, style, dashboardId, members, fetchMembers }) => {
    const { userData } = useUser();
    const [email, setEmail] = useState("");
    const theme = useContext(ThemeContext).theme;
    const [errors, setErrors] = useState({});
    const [addBoardSnackbar, setAddBoardSnackbar] = useState(false);
    const [dashboards, setDashboards] = useState([]);
    const [openAddMember, setOpenAddMember] = useState(false);
    // user not found snackbar
    const [userNotFoundSnackbar, setUserNotFound] = useState(false);
    // const [members, setMembers] = useState([]);
    const handleCloseModal = () => {
        setEmail("");
        handleClose();
    };

    const handleConfirm = async () => {
        try {

            const userAdded = await addUserForDashboard(email, dashboardId);
            if (userAdded == "added-successfully") {
                handleCloseModal();
                setAddBoardSnackbar(true);
                fetchMembers();
                handleCloseModal();
            }
            else if (userAdded == 'user-not-found') {
                setUserNotFound(true);
            }
            else {
                console.error('error', userAdded);
            }
        } catch (error) {
            console.error("Error adding user to dashboard:", error);
        }
    };

    const handleChange = (e) => {
        setEmail(e.target.value);
    };
    const handleDeleteMember = async (email, dashboardId) => {
        try {
            await removeUserFromDashboard(email, dashboardId);
            fetchMembers();
        } catch (error) {
            console.error("Error deleting member:", error);
        }
    };
    return (
        <div>
            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal"
            >
                <Box sx={style}>
                    <div className="flex justify-end items-end">
                        <button type="button" onClick={handleCloseModal}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="24"
                                viewBox="0 0 27 24"
                                fill="none"
                            >
                                <path
                                    d="M20.25 5.93091L6.75 17.3185"
                                    stroke="#525256"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6.75 5.93091L20.25 17.3185"
                                    stroke="#525256"
                                    strokeWidth="2.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="flex justify-center items-center mb-2">
                        <div className="">
                            <img src={Avatars} className="w-40 ml-5" />
                            <h3 className="text-dark_black font-[700] text-[28px]">
                                Invite a member
                            </h3>
                        </div>
                    </div>
                    <div className={`text-center  text-[14px] font-[500] ${theme === "dark" ? "text-[#dad7cd]" : "text-[#475467]"}`}>
                        Please enter a user email address <br /> to collaborate on this dashboard.
                    </div>
                    <div>
                        <form className="flex flex-col">
                            <label
                                htmlFor="name"
                                className={`my-3 text-[15px] font-[500] text-dark_black`}
                            >
                                Add Email
                            </label>
                            <input
                                placeholder="Enter member email"
                                label="Email"
                                name="Email"
                                type="text"
                                fullWidth
                                value={email}
                                onChange={handleChange}
                                className={`rounded-[8px]  mb-[10px] px-3 focus:outline-none h-[54px] ${theme === "dark"
                                    ? "bg-[#4A4A4A]"
                                    : "bg-white border border-[#D0D5DD]"
                                    } `}
                            />
                            <span className="text-red-500">{errors.name}</span>
                            <div className={`my-3 text-[15px] font-[500] text-dark_black`}>
                                Members
                            </div>
                            {members.length == 0 && <div className='flex justify-center items-center font-[500] text-[15px] '> No members for this dashboard.</div>}

                            {members.map((member, index) => (
                                <div key={index} className="flex flex-col gap-4">
                                    <div className="flex justify-between mt-4">
                                        <div className="flex">
                                            <div>
                                                <img src={member.picture} className="w-10 h-10 rounded-[50%]" />
                                            </div>
                                            <div className="flex flex-col ml-2">
                                                <span className="text-[#344054] text-[14px] font-[600]">{member.firstName}</span>
                                                <span className="text-[#475467] text-[14px] font-[400]">{member.email}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <img src={trash} className="w-5 h-5 cursor-pointer" onClick={() => handleDeleteMember(member.email, dashboardId)} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <hr className="w-full mt-4" />
                            <div className="flex justify-end mt-[20px] mb-[45px]">
                                <div className="flex flex-col lg:flex-row gap-[16px] modal-cancel w-full">
                                    <button
                                        className="bg-[#D9D9D9] rounded-[10px] px-[16px] py-[10px] w-full text-black"
                                        type="button"
                                        onClick={handleCloseModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="text-white rounded-[10px] px-[16px] py-[10px] w-full "
                                        type="button"
                                        onClick={handleConfirm}
                                        style={{
                                            backgroundColor:
                                                localStorage.getItem("theme-color") || userData.themeColor,
                                        }}
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
            <CustomSnackbar
                openSnackbar={addBoardSnackbar}
                closeSnackbar={() => {
                    setAddBoardSnackbar(false);
                }}
                message="The team member has been invited successfully"
            />
            <CustomSnackbar
                openSnackbar={userNotFoundSnackbar}
                closeSnackbar={() => {
                    setUserNotFound(false);
                }}
                message="The member does'nt exist in our platform."
            />
        </div>
    );
};

export default InviteTeamModal;
