import React from "react";
import ReactApexChart from "react-apexcharts";

const TemperatureChart = ({ startDate, endDate }) => {
  const generateDateRange = (start, end) => {
    const dateRange = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      const formattedDate = currentDate.toLocaleDateString();
      dateRange.push(formattedDate);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateRange;
  };
  const options = {
    chart: {
      type: "area",
      stacked: false,
      toolbar: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "category",
      categories: generateDateRange(startDate, endDate),
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
    },
    colors: ["#168004"],
  };

  const series = [
    {
      name: "Spline Area Chart",
      data: [30, 40, 25, 50, 49, 21, 70, 14, 25, 47, 21, 70, 14, 21],
    },
  ];

  return (
    <div className="spline-area-chart">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={300}
      />
    </div>
  );
};

export default TemperatureChart;
