import axios from "axios";

const getLogsByUser = async (userId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ` + token

    };
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/activity-logs/by-user/${userId}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching logs:', error.message);
    throw error;
  }
};

const clearLogsByUser = async (userId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ` + token

    };
    const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/activity-logs/clear-by-user/${userId}`, { headers });
    return response.data;
  } catch (error) {
    console.error('Error clearing logs:', error.message);
    throw error;
  }
};

export { getLogsByUser, clearLogsByUser };
