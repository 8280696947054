import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo-black.svg";
import OpenIcon from "../../../assets/icons/CloseIcon.svg";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import "./navbar.css";
import { useUser } from "../../../state/UserContext";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();
  const { userData } = useUser();
  const handleNav = () => {
    setNav(!nav);
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 60) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`w-full navbar h-[120px] after:block after:h-px after:w-full lg:after:hidden relative  z-[10000] ${
        scrolled ? "scrolled" : ""
      }`}
    >
      <div className="max-w mx-auto px-2 sm:px-6 lg:px-4 flex  ">
        <div className="w-1/6 header-column ">
          <img
            className="p-2 mt-6 mb-2 lg:ml-16 max-w-[130px] min-w-[90px] w-full justify-center items-center cursor-pointer"
            src={logo}
            alt="Logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div className=" w-2/3 header-column">
          <ul className="hidden lg:flex p-6 mt-1 justify-start items-center h-full ">
            <li className={`p-4 nav-titles active`}>
              {" "}
              <Link to="/">Home</Link>
            </li>
            <li className="p-4 nav-titles">
              <Link
                to="/#features"
                onClick={() => (window.location.href = "/#features")}
              >
                Features
              </Link>
            </li>
            <li className="p-4 nav-titles">
              <Link
                to="/#why-choose-us"
                onClick={() => (window.location.href = "#why-choose-us")}
              >
                Why choose Us
              </Link>
            </li>
            <li className="p-4 nav-titles">
              <div className="flex space-x-2">
                <a href="/documentation">Documentation</a>
              </div>
            </li>
          </ul>
        </div>
        <div className="  header-column">
          <ul className="hidden lg:flex p-2 md:ml-6 lg:ml-6 mr-16 items-center justify-end h-full">
            {userData ? (
              <li className="p-2 mt-3">
                <button
                  className="dashboard-button"
                  onClick={() => navigate("/home-dashboard")}
                >
                  Dashboard
                </button>
              </li>
            ) : (
              <>
                <li className="p-2 mt-3 ">
                  <button
                    className="Login-header-button w-[100px]"
                    onClick={() => navigate("/login")}
                  >
                    Sign In
                  </button>
                </li>
                <li className="p-2 mt-3">
                  <button
                    className="demo-button w-[100px]"
                    onClick={() => navigate("/register")}
                  >
                    Sign Up
                  </button>
                </li>
              </>
            )}
          </ul>
          <div
            onClick={handleNav}
            className="lg:hidden flex  cursor-pointer absolute sm:top-[50px] top-[40px] xs:right-[50px] right-[30px]"
          >
            {nav ? (
              <img
                src={OpenIcon}
                color="black"
                className="navIcon "
                alt="navicon"
              />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="16"
                viewBox="0 0 24 16"
                fill="none"
              >
                <path
                  d="M1.33333 16C0.955556 16 0.638889 15.8722 0.383333 15.6167C0.127778 15.3611 0 15.0444 0 14.6667C0 14.2889 0.127778 13.9722 0.383333 13.7167C0.638889 13.4611 0.955556 13.3333 1.33333 13.3333H22.6667C23.0444 13.3333 23.3611 13.4611 23.6167 13.7167C23.8722 13.9722 24 14.2889 24 14.6667C24 15.0444 23.8722 15.3611 23.6167 15.6167C23.3611 15.8722 23.0444 16 22.6667 16H1.33333ZM1.33333 9.33333C0.955556 9.33333 0.638889 9.20556 0.383333 8.95C0.127778 8.69444 0 8.37778 0 8C0 7.62222 0.127778 7.30556 0.383333 7.05C0.638889 6.79444 0.955556 6.66667 1.33333 6.66667H22.6667C23.0444 6.66667 23.3611 6.79444 23.6167 7.05C23.8722 7.30556 24 7.62222 24 8C24 8.37778 23.8722 8.69444 23.6167 8.95C23.3611 9.20556 23.0444 9.33333 22.6667 9.33333H1.33333ZM1.33333 2.66667C0.955556 2.66667 0.638889 2.53889 0.383333 2.28333C0.127778 2.02778 0 1.71111 0 1.33333C0 0.955556 0.127778 0.638889 0.383333 0.383333C0.638889 0.127778 0.955556 0 1.33333 0H22.6667C23.0444 0 23.3611 0.127778 23.6167 0.383333C23.8722 0.638889 24 0.955556 24 1.33333C24 1.71111 23.8722 2.02778 23.6167 2.28333C23.3611 2.53889 23.0444 2.66667 22.6667 2.66667H1.33333Z"
                  fill="#168004"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
      <div
        className={
          nav
            ? "flex lg:hidden text-black bg-white min-w-[350px] background-login border w-full flex-col items-center absolute z-10 right-0"
            : "hidden"
        }
      >
        <ul className="w-full flex flex-col items-center ">
          <li className="p-4 text-[16px] font-Poppins font-[600] mt-3 ">
            <Link to="/">Home</Link>
          </li>
          <li className="p-4 text-[16px] font-Poppins font-[600]">
            <Link
              to="/#features"
              onClick={() => (window.location.href = "/#features")}
            >
              Features
            </Link>
          </li>
          <li className="p-4 text-[16px] font-Poppins font-[600]">
            <Link
              to="/#why-choose-us"
              onClick={() => (window.location.href = "#why-choose-us")}
            >
              Why choose Us
            </Link>
          </li>
          <li className="p-4 text-[16px] font-Poppins font-[600]">
            <a href="/documentation">Documentation</a>
          </li>
          {userData ? (
            <li className="p-2 mt-3">
              <button
                className="dashboard-button"
                onClick={() => navigate("/home-dashboard")}
              >
                Dashboard
              </button>
            </li>
          ) : (
            <div className="sm:w-[50%] w-[80%] mb-5">
              <li className="p-2 mt-3">
                <button
                  className="Login-header-button w-full"
                  onClick={() => navigate("/login")}
                >
                  Sign In
                </button>
              </li>
              <li className="p-2 mt-3">
                <button
                  className="demo-button w-full"
                  onClick={() => navigate("/register")}
                >
                  Sign Up
                </button>
              </li>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
