import React from "react";
import { Modal, Box } from "@mui/material";
import { ThemeContext } from "../../../state/ThemeContext";
import { useContext } from "react";
import trash from "../../../assets/images/trash-icon.svg";
import closeIcon from "../../../assets/icons/close_icon.svg";
const DeleteBoardModal = ({
  open,
  handleClose,
  style,
  handleDeleteBoard,
  boardId,
}) => {
  const theme = useContext(ThemeContext).theme;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <Box sx={style}>
        <div className="flex justify-between items-center mb-2">
          <div className="flex">
            <img src={trash} />
            <h3
              className={`font-[700] text-[24px] mt-2  ml-3 ${theme === "dark" ? "text-white" : "text-black"
                }`}
            >
              Delete
            </h3>
          </div>
          <button type="button" onClick={handleClose}>
           <img src={closeIcon} height="14" width="14" />
          </button>
        </div>
      
        <div>
          <p
            className={`text-[20px]  ${theme === "dark" ? "text-[#858580]" : "text-[#858585]"
              }`}
          >
            Are you sure you want to delete this board ?       </p>
        </div>
    
        <div className="flex justify-end mt-[20px] mb-[45px]">
          <div className="flex flex-wrap gap-[16px] modal-cancel w-full lg:w-auto">
            <button
              className="bg-[#D9D9D9] rounded-[10px] px-[16px] py-[10px] w-full lg:w-auto text-black"
              type="button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="text-white rounded-[10px] px-[16px] py-[10px] w-full lg:w-auto"
              type="button"
              onClick={() => {
                handleDeleteBoard(boardId);
              }}
              style={{
                backgroundColor: "#D92D20"
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteBoardModal;
