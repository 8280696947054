import axios from "axios";

const clearWidgetFromHome = async (widgetId, userId) => {

  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ` + token

    };
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/custom-dashboard/remove-widget`, {
      widgetId, userId
    }, { headers })
    return (response.status == 200);
  } catch (error) {
    console.error('error when trying to clear the widget from home page');
  }

}
const getWidgetById = async (widgetId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token is missing");
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ` + token

    };
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/widgets/${widgetId}`, { headers });
    return response.data;
  } catch (error) {
    console.error("Error fetching widget by ID:", error);
    throw error;
  }
};
export { clearWidgetFromHome };