import React from "react";
import ReactApexChart from "react-apexcharts";
import "../../pages/dashboard/Dashboard.css";

function Chart({ chart }) {
  const { type} = chart;


  const options = {
    chart: {
      type: type,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    colors: ["#168004"],
  };

  const series = [
    {
      name: "Spline Area Chart",
      data: [70],
    },
  ];

  return (
    <div className="chartContainer p-2">
      <h2 className="mt-4">Chart {type}</h2>
      <ReactApexChart options={options} series={series} type={type} />
    </div>
  );
}

export default Chart;
