import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedWidget: null,
  mode: "light",
};

export const sensySlice = createSlice({
  name: "sensy",
  initialState,
  reducers: {
    resetState: (state) => {
      return initialState;
    },

    setSelectedWidget: (state, actions) => {
      state.selectedWidget = actions.payload;
    },

    setMode: (state, actions) => {
      state.mode = actions.payload.mode;
    },
  },
});

export const { setSelectedWidget, setMode } = sensySlice.actions;
export default sensySlice.reducer;
