import React, { useState } from "react";
import { resetPassword } from "../../services/authService";
import { useNavigate, useParams } from "react-router";
import logo from "../../assets/images/logoDark.png";
import CustomSnackbar from "../../components/CustomSnackbar";

export default function ResetPassword() {
  const { resetToken } = useParams();

  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [resetSnackbar, setResetSnackbar] = useState(false);
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await resetPassword(resetToken, password, confirmPassword);
      setResetSnackbar(true);
      navigate("/login");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="w-full h-screen flex justify-center items-center bg-gray-100 background-image-login background-login">
      <div className="lg:w-1/2 w-full h-full flex flex-col mx-auto my-auto p-8">
        <div className="flex flex-col justify-center items-center">
          <img className="mt-10 lg:mt-12" src={logo} alt="Logo" />
        </div>

        <div className="flex flex-col items-center justify-center mt-8">
          <div className="flex justify-start items-start mt-12">
            <h1 className="login-title lg:mt-8 text-green-600 font-inter font-semibold text-[25px] sm:text-[30px] leading-6 sm:leading-8">
              Reset Password
            </h1>
          </div>

          <form
            className="w-full lg:mt-6 sm:w-full md:w-full sm:block p-12 space-y-4"
            onSubmit={handleResetPassword}
          >
            <div>
              <label htmlFor="password" className="form-label">
                New Password
              </label>
              <div className="flex input-container">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  className="w-full sm:w-full md:w-full form-input-register p-2 pr-10 border rounded-lg"
                  placeholder="Enter new password"
                  required
                />
              </div>
            </div>

            <div>
              <label htmlFor="confirmPassword" className="form-label">
                Confirm Password
              </label>
              <div className="flex input-container">
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  id="confirmPassword"
                  className="w-full sm:w-full md:w-full form-input-register p-2 pr-10 border rounded-lg"
                  placeholder="Confirm new password"
                  required
                />
              </div>
            </div>

            {error && <div className="text-red-500 mb-4">{error}</div>}

            <div className="relative">
              <button
                type="submit"
                className="form-input-register mt-0 login-button rounded-lg p-2"
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>

      <CustomSnackbar
        openSnackbar={resetSnackbar}
        closeSnackbar={() => {
          setResetSnackbar(false);
        }}
        message="Password reset successfully!"
      />
    </div>
  );
}
